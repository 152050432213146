/* global BrowserPrint , Zebra */

const ZebraPlugin = {
  install(Vue) {
    Vue.prototype.$zebraPlugin = {
        async getPrinters() {
            return new Promise((resolve, reject) => {
              BrowserPrint.getLocalDevices(async function(deviceList) {
                if (deviceList && deviceList.printer) {
                  let printerList = [];
                  for (const p of deviceList.printer) {
                    const printer = new Zebra.Printer(p);
                    const printerStatus = await printer.getStatus();
                    printerList.push({
                      status: printerStatus.isPrinterReady(),
                      message: printerStatus.getMessage(),
                      ...printer
                    });
                  }
                  resolve(printerList);
                } else {
                  reject(new Error("Aucune imprimante trouvée"));
                }
              });
            });
        },          
        getDefaultPrinter(){
            return new Promise((resolve, reject) => {
                BrowserPrint.getDefaultDevice("printer",async function(device) {
                  if (device) {
                    device.send("^XA^HH^XZ", function(){
                      device.read(function(){
                         resolve(new Zebra.Printer(device));
                      }, function(){
                          reject(new Error("Aucune imprimante trouvée"));
                      });
                  }, function(){
                      reject(new Error("Aucune imprimante trouvée"));
                  });
                  } else {
                    reject(new Error("Aucune imprimante trouvée"));
                  }
                });
            });
        },
    };
  }
};

export default ZebraPlugin;
