<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" sm="6" md="3">
        <v-form @submit="onSubmit" onSubmit="return false;">
          <v-text-field
            :loading="loading"
            v-model="product"
            label="Inscrire nom ou DIN"
            placeholder="Nom ou DIN du produit"
            outlined
            autofocus
          >
            <v-icon slot="append" type="submit"> mdi-magnify </v-icon>
          </v-text-field>
        </v-form>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-subheader v-bind:class="{ 'red--text': productResults.length > 50 }">{{ productResults.length }} résultats</v-subheader>
      </v-col>
      
      <v-col cols="12">
          <v-alert dense type="info">Attention, va afficher les génériques et les autres concentrations.</v-alert>
        <v-data-table
          dense
          :headers="headers"
          :items="productResults"
          :items-per-page="100"
          :loading="loading"
          :footer-props="{ 'items-per-page-options': [50, 100, -1] }"
          class="elevation-1"
          :sort-by="['DernierService']"
          :sort-desc="[true]"
        >
          <template v-slot:[`item.pharmacie`]="{ item }">
            <span
              :class="getColor(item.pharmacie)"
              class="white--text text-uppercase"
              >&brvbar; {{ item.pharmacie }} &brvbar;</span
            >
          </template>
          <template v-slot:[`item.DernierService`]="{ item }">
            {{ item.DernierService | dateRelativeMaxAujourdhui }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import $axios from "../plugins/axios";

export default {
  name: "Inventaire",
  data() {
    return {
      product: "",
      loading: false,
      productResults: [],
      headers: [
        { text: "Pharmacie", value: "pharmacie" },
        { text: "Nom", value: "Description" },
        { text: "Force", value: "Force" },
        { text: "Forme", value: "Forme" },
        { text: "Quantite émise dernier 12 mois", value: "QuantiteEmise" },
        { text: "Dernière émission", value: "DernierService" },
      ],
    };
  },
  filters: {
    dateRelativeMaxAujourdhui: (value) => {
      if (!value) return "Date invalide";
      value = value.toString();
      var date1 = new Date(value);
      var now = new Date();
      var Difference_In_Time = date1.getTime() - now.getTime();
      var Difference_In_Days = Math.floor(
        Difference_In_Time / (1000 * 3600 * 24)
      );
      if (Difference_In_Days < 1) {
        return (
          date1.getDate() +
          " " +
          date1.toLocaleString("fr", { month: "long" }) +
          " " +
          date1.getFullYear()
        );
      } else {
        return "renouv. futurs";
      }
    },
  },
  computed: {},
  methods: {
    async onSubmit() {
      this.loading = true;
      $axios
        .get(process.env.VUE_APP_API_URL + "/product/" + this.product)
        .then((response) => (this.productResults = response.data))
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
      console.log(this.productResults);
    },
    getColor(pharmacie) {
      if (pharmacie == "saules") return "blue";
      else if (pharmacie == "oasis") return "orange";
      else if (pharmacie == "paton") return "green";
      else return "pink";
    },
  },
};
</script>
