<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="3">
        <v-card outlined>
          <v-card-text>
            <v-text-field outlined dense clearable v-model="dateSelection" :max="maxDate" type="date"
              label="Prix produit à la date"></v-text-field>
            <v-btn color="primary" class="mr-4" v-on:click="printProductList">Imprimer</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card outlined>
          <v-card-text>
              <v-btn color="primary" class="mx-1" small v-on:click="downloadCatalogue"
                :loading="this.loading" dense>Télécharger catalogue</v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card outlined>
          <v-card-text>
              <v-btn color="primary" class="mx-1" small v-on:click="importCatalogue"
                :loading="this.$store.state.app.isImportingCatalogue || this.loading" dense>Importer catalogue</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import $axios from "../../plugins/axios";

export default {
  name: "Catalogue",
  data() {
    return {
      loading : false,
      dateSelection : '',
      maxDate : '',
    };
  },
  mounted(){
    this.dateSelection = new Date().toLocaleDateString("en-CA")
    this.maxDate = new Date().toLocaleDateString("en-CA")
  },
  methods: {
    async importCatalogue() {
      try {
        this.$store.commit("updateImportCatalogue", true);
        let response = await $axios.get(
          process.env.VUE_APP_API_URL + `/mckesson/catalogue/import`
        );
        alert(response.data.msg);
      } catch (error) {
        alert(error);
      } finally {
        this.$store.commit("updateImportCatalogue", false);
      }
    },
    printProductList() {
      window.open(
        process.env.VUE_APP_API_URL +
        `/mckesson/printProductList?date_selection=${this.dateSelection}`
      );
    },
    async downloadCatalogue() {
      try {
        this.loading = true;
        let response = await $axios.get(
          process.env.VUE_APP_API_URL + `/mckesson/catalogue/download`
        );
        if(response.data.error){
          alert(response.data.msg);
        }
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
