import Vue from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
require('dayjs/locale/fr')

dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.locale('fr')
Object.defineProperties(Vue.prototype, {
    $date: {
        get() {
            return dayjs
        }
    }
});