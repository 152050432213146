import Vue from 'vue'
import Vuex from 'vuex'
import sync from './modules/sync'
import auth from "./modules/auth";
import app from "./modules/app";
import zebraPrinter from "./modules/zebraPrinter";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    sync,
    app,
    zebraPrinter
  },
  state: {
  },
  getters: {
  },
  actions: {
  },
  mutations: {
  },
})
