<template>
  <v-card outlined>
    <v-card-text>
      <v-autocomplete
        v-model="foyer"
        :items="foyers"
        item-value="id"
        item-text="nom_reflex"
        no-data-text="'AUCUN RÉSULTAT"
        outlined
        solo
        dense
        hide-details
        label="Foyer"
        required
        v-on:change="selectDefaultValue"
      >
        <template v-slot:item="data">
          <template>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.nom_reflex }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
      <br/>
      <v-alert dense v-if="foyer!= 0 && formData.imprimerErreur!=selected_foyer.default_erreurs && selected_foyer.default_erreurs!=null" dismissible type="warning"
          >Attention, la valeur par défaut pour 'Imprimer erreurs' est FAUX
      </v-alert> 
      <v-checkbox
        dense
        outlined
        v-model="formData.imprimerErreur"
        :label="`Imprimer erreurs`"
      ></v-checkbox>
      <v-checkbox
        dense
        outlined
        v-model="formData.transactionProbleme"
        :label="`Transaction en problème`"
      ></v-checkbox>
      <v-alert dense v-if="foyer!= 0 && formData.imprimerResume!=selected_foyer.default_resume && selected_foyer.default_resume!=null" dismissible type="warning"
          >Attention, la valeur par défaut pour 'Imprimer résumé' est FAUX
      </v-alert> 
      <v-checkbox
        dense
        outlined
        v-model="formData.imprimerResume"
        :label="`Imprimer résumé`"
      ></v-checkbox>
      <v-alert dense v-if="foyer!= 0 && formData.imprimerEtatCompte!=selected_foyer.default_etat_de_compte && selected_foyer.default_etat_de_compte!=null" dismissible type="warning"
          >Attention, la valeur par défaut pour 'Imprimer état de compte' est FAUX
      </v-alert> 
      <v-checkbox
        dense
        outlined
        v-model="formData.imprimerEtatCompte"
        :label="`Imprimer état de compte`"
      ></v-checkbox>
      <v-alert dense v-if="foyer!= 0 && formData.imprimerCopieEtatCompte!=selected_foyer.default_copie_etat_de_compte && selected_foyer.default_copie_etat_de_compte!=null" dismissible type="warning"
          >Attention, la valeur par défaut pour 'Imprimer copie état de compte pour pharmacie' est FAUX
      </v-alert>
      <v-checkbox
        dense
        outlined
        v-model="formData.imprimerCopieEtatCompte"
        :label="`Imprimer copie état de compte pour pharmacie`"
      ></v-checkbox>  
      <v-alert dense v-if="foyer!= 0 && formData.imprimerFacture!=selected_foyer.default_facture && selected_foyer.default_facture!=null" dismissible type="warning"
          >Attention, la valeur par défaut pour 'Imprimer facture' est FAUX
      </v-alert>
      <v-checkbox
        dense
        outlined
        v-model="formData.imprimerFacture"
        :label="`Imprimer facture`"
      ></v-checkbox>
      <v-alert dense v-if="foyer!= 0 && formData.rectoVerso!=selected_foyer.default_recto_verso && selected_foyer.default_recto_verso!=null" dismissible type="warning"
          >Attention, la valeur par défaut pour 'Recto-verso' est FAUX
      </v-alert>
      <v-checkbox
        dense
        outlined
        v-model="formData.rectoVerso"
        :label="`Recto-verso`"
      ></v-checkbox>
      <v-alert dense v-if="foyer!= 0 && formData.separerFichier!=selected_foyer.default_separer_fichiers && selected_foyer.default_separer_fichiers!=null" dismissible type="warning"
          >Attention, la valeur par défaut pour 'Séparer fichier' est FAUX
      </v-alert>
      <v-checkbox
        dense
        outlined
        v-model="formData.separerFichier"
        :label="`Séparer fichier`"
      ></v-checkbox>
      <v-alert dense v-if="foyer!= 0 && formData.imprimerPPA!=selected_foyer.default_ppa && selected_foyer.default_ppa!=null" dismissible type="warning"
          >Attention, la valeur par défaut pour 'Imprimer PPA seulement' est FAUX
      </v-alert>
      <v-checkbox
        dense
        outlined
        v-model="formData.imprimerPPA"
        :disabled="formData.exclurePPA"
        :label="`Imprimer PPA seulement`"
      ></v-checkbox>
      <v-checkbox
        dense
        outlined
        v-model="formData.exclurePPA"
        :label="`Exclure les PPA`"
        :disabled="formData.imprimerPPA"
      ></v-checkbox>
      <v-checkbox
        dense
        outlined
        v-model="formData.imprimerListePPA"
        :label="`Imprimer liste clients PPA`"
      ></v-checkbox>
      <v-alert dense v-if="foyer!= 0 && !isDateSelectionDefaultValue() && selected_foyer.default_services_depuis_month!=null" dismissible type="warning"
          >Attention, la valeur par défaut pour 'Patients avec services depuis' est FAUX
      </v-alert>
      <v-text-field
        outlined
        dense
        clearable
        v-model="dateSelection"
        type="date"
        max="2999-01-01"
        label="Patients avec services depuis"
      ></v-text-field>

      <v-text-field v-if="formData.imprimerFacture || formData.transactionProbleme"
        outlined
        dense
        clearable
        v-model="formData.debutCycle"
        type="date"
        label="Début facture"
      ></v-text-field>

      <v-text-field v-if="formData.imprimerFacture || formData.transactionProbleme"
        outlined
        dense
        clearable
        v-model="formData.finCycle"
        type="date"
        :label="`Fin facture`"
      ></v-text-field>
      <v-checkbox
        dense
        outlined
        v-model="formData.trierParType"
        :label="`Trier par type de facture (papier/courriel/autre adresse...)`"
      ></v-checkbox>
      <v-alert dense v-if="foyer!= 0 && formData.trierParType!=selected_foyer.default_tri_facture && selected_foyer.default_tri_facture!=null" dismissible type="warning"
          >Attention, la valeur par défaut pour 'Trier par type de facture' est FAUX
      </v-alert>
      <v-checkbox
        dense
        outlined
        v-model="formData.imprimerHistorique"
        :label="`Imprimer historique paiement`"
      ></v-checkbox>



      <v-btn color="primary" class="mr-4" v-on:click="generateFile">Générer</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "../../plugins/axios";
import { mapGetters } from "vuex";


export default {
  data() {
    
    return {
      foyer: 0,
      formData: {
        imprimerErreur: true,
        transactionProbleme: true,
        imprimerEtatCompte: true,
        imprimerCopieEtatCompte: true,
        imprimerFacture: true,
        imprimerResume: true,
        imprimerPPA : false,
        exclurePPA : false,
        imprimerListePPA : false,
        trierParType : false,
        imprimerHistorique : false,
        separerFichier: false,
        rectoVerso: true,
        finCycle : '',
        debutCycle : '',
      },
      dateSelection: new Date().toLocaleDateString("en-CA"),
      foyers: [],
      selected_foyer : null,
    };
  },
  mounted() {
    axios.get(process.env.VUE_APP_API_URL + "/foyer?show_all=true").then((response) => {
      this.foyers = response.data;
    });
    let currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 1);
    this.dateSelection = currentDate.toLocaleDateString("en-CA");

  },
  computed: {
    ...mapGetters(["db", "decoded"]),
  },
  methods: {

    generateFile(){
      if(this.foyer!=0 && this.dateSelection!=null){
        if((this.formData.imprimerFacture || this.formData.transactionProbleme) && (this.formData.debutCycle== null || this.formData.finCycle== null) ){
          return ;
        }
        window.open(`${process.env.VUE_APP_API_URL}/etat_facturation?pharmacie_id=${this.decoded.succursale.id}&foyer=${this.foyer}&date_selection=${this.dateSelection}&erreur=${this.formData.imprimerErreur}&facture=${this.formData.imprimerFacture}&etat_de_compte=${this.formData.imprimerEtatCompte}&copie_etat_de_compte=${this.formData.imprimerCopieEtatCompte}&resume=${this.formData.imprimerResume}&recto_verso=${this.formData.rectoVerso}&separer=${this.formData.separerFichier}&ppa=${this.formData.imprimerPPA}&debut=${this.formData.debutCycle}&fin=${this.formData.finCycle}&trier=${this.formData.trierParType}&historique=${this.formData.imprimerHistorique}&transaction_probleme=${this.formData.transactionProbleme}&exclure_ppa=${this.formData.exclurePPA}&liste_ppa=${this.formData.imprimerListePPA}`);
      }
    },
    selectDefaultValue(){
      if(this.foyer!=0){
        let item_foyer = this.foyers.find(x=>x.id == this.foyer);
        this.selected_foyer = item_foyer;
                  let currentDate = new Date();
          if(item_foyer.default_services_depuis_month!=null){
            currentDate.setMonth(currentDate.getMonth() + item_foyer.default_services_depuis_month);
            this.dateSelection = currentDate.toLocaleDateString("en-CA");
          }else{
            currentDate.setFullYear(currentDate.getFullYear() - 1);
            this.dateSelection = currentDate.toLocaleDateString("en-CA");
          }
          if(item_foyer.default_erreurs!=null){
            this.formData.imprimerErreur = Boolean(item_foyer.default_erreurs)
          }else{
            this.formData.imprimerErreur = true
          }
          if(item_foyer.default_transaction_probleme!=null){
            this.formData.transactionProbleme = Boolean(item_foyer.default_transaction_probleme)
          }else{
            this.formData.transactionProbleme = true
          }
          if(item_foyer.default_resume!=null){
            this.formData.imprimerResume = Boolean(item_foyer.default_resume)
          }else{
            this.formData.imprimerResume = true
          }
          if(item_foyer.default_etat_de_compte!=null){
            this.formData.imprimerEtatCompte = Boolean(item_foyer.default_etat_de_compte)
          }else{
            this.formData.imprimerEtatCompte = true
          }
          if(item_foyer.default_copie_etat_de_compte!=null){
            this.formData.imprimerCopieEtatCompte = Boolean(item_foyer.default_copie_etat_de_compte)
          }else{
            this.formData.imprimerCopieEtatCompte = true
          }
          if(item_foyer.default_facture!=null){
            this.formData.imprimerFacture = Boolean(item_foyer.default_facture)
          }else{
            this.formData.imprimerFacture = true
          }
          if(item_foyer.default_recto_verso!=null){
            this.formData.rectoVerso = Boolean(item_foyer.default_recto_verso)
          }else{
            this.formData.rectoVerso = false
          }
          if(item_foyer.default_separer_fichiers!=null){
            this.formData.separerFichier = Boolean(item_foyer.default_separer_fichiers)
          }else{
            this.formData.separerFichier = false
          }
          if(item_foyer.default_ppa!=null){
            this.formData.imprimerPPA = Boolean(item_foyer.default_ppa)
          }else{
            this.formData.imprimerPPA = false
          }
          if(item_foyer.default_tri_facture!=null){
            this.formData.trierParType = Boolean(item_foyer.default_tri_facture)
          }else{
            this.formData.trierParType = false
          }
          this.formData.exclurePPA = false ;
          this.formData.imprimerHistorique = false;
          this.formData.imprimerListePPA = false;
          this.updateDateCycle();
      }else{
        this.formData.debutCycle = '';
        this.formData.finCycle = '';
      }
        
    },
    updateDateCycle(){
        var foyer = this.db.foyer[this.foyer];
        var today = this.$date.utc();
        let date_cycle = this.$date.utc(today.format('YYYY-MM-DD'));
        date_cycle = date_cycle.subtract(3, 'month');
        let cycle = this.getCycleFacturation(foyer.debut_cycle_facturation,date_cycle)
        this.formData.debutCycle = cycle.last_completed_cycle.debut;
        this.formData.finCycle = cycle.last_completed_cycle.fin
    },
    isDateSelectionDefaultValue(){
      
      if(this.selected_foyer.default_services_depuis_month!=null){
        let currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() + this.selected_foyer.default_services_depuis_month);
        return currentDate.toLocaleDateString("en-CA") === this.dateSelection;
      }
      return true;
    }
  }
};
</script>

<style>
</style>