<template>
    <v-card-text>
      <v-row v-if="view === 'paiement'">
        <v-col cols="12" md="4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Montant total</v-list-item-title>
              <v-list-item-subtitle>{{ data.total | formatPrice }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" md="4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Méthode</v-list-item-title>
              <v-list-item-subtitle>{{ data.description | formatString }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
  
        <v-col cols="12" md="4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Date paiement</v-list-item-title>
              <v-list-item-subtitle>{{
                data.timestamp | toDate("-")
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
  
        <v-col cols="12" md="12" v-if="data.description == 'ajustement'">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Description ajustement</v-list-item-title>
              <v-list-item-subtitle>{{
                data.desc_ajustement | formatString
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
  
        <v-col cols="12" md="12">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title
                >Notes pour pharmacie seulement</v-list-item-title
              >
              <v-list-item-subtitle>{{ data.notes | formatString }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-row v-if="view === 'patient'">
        <v-col cols="12" md="4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Date ouverture compte</v-list-item-title>
              <v-list-item-subtitle>{{
                data.date_ouverture_compte | toDate("-")
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" md="4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Solde ouverture compte</v-list-item-title>
              <v-list-item-subtitle>{{
                data.solde_ouverture_compte | formatPrice
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" md="4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Date fermeture compte</v-list-item-title>
              <v-list-item-subtitle>{{
                data.date_fermeture_compte | toDate("-")
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
  
        <v-col cols="12" md="4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Carte de crédit</v-list-item-title>
              <v-list-item-subtitle>{{ data.cc }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" md="4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Carte de crédit expiration</v-list-item-title>
              <v-list-item-subtitle>{{ data.cc_exp }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" md="4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Carte de crédit CVV</v-list-item-title>
              <v-list-item-subtitle>{{ data.cc_cvv }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
  
        <v-col cols="12" md="4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Paiement pré-autorisé (PPA)</v-list-item-title>
              <v-list-item-subtitle>{{
                data.ppa | booleanToString
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" md="4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>PPA autorisation notes</v-list-item-title>
              <v-list-item-subtitle>{{
                data.ppa_autorisation | formatString
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" md="4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Courriel pour facturation</v-list-item-title>
              <v-list-item-subtitle>{{
                data.facture_par_courriel_adresse | formatString
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" md="4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Facture papier</v-list-item-title>
              <v-list-item-subtitle>{{
                data.facture_methode | booleanToString
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
  
        <v-col cols="12" md="4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Adresse pour poster facture</v-list-item-title>
              <v-list-item-subtitle>{{ data.facture_autre_adresse_ou_fax | formatString }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
  
        <v-col cols="12" md="8">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Note</v-list-item-title>
              <v-list-item-subtitle>{{ data.notes|formatString }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card-text>
  </template>
  
  <script>
  export default {
    props: ["data", "view"],
    filters: {
      formatString: function (value, placeholder = "-") {
        if (value === "" || typeof value === "undefined" || value == null) {
          return placeholder;
        } else {
          return value;
        }
      },
    },
  };
  </script>
  
  <style>
  </style>