import axios from 'axios';
import store from "../store";


const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

axiosInstance.interceptors.request.use(
  config => {
    config.headers.jwt = store.state.auth.authToken;
    config.headers.user_id = store.state.auth.user ? store.state.auth.user.id : null;
    return config;
  },
  error => Promise.reject(error)
);

export default axiosInstance;