module.exports =(function (value,default_val='') {
    if(typeof value==='undefined'|| value==null || value===''){
        return default_val;
    }else{
        if(value.includes('T')){
            return value.split('T')[0]
        }else{
            return value.split(' ')[0]
        }
    }
    
    
})