var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('h3',[_vm._v("Historique des changements")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
      { text: 'Information', value: 'type' },
      {
        text: 'Date de mise à jour',
        align: 'start',
        sortable: true,
        value: 'timestamp',
      },
      { text: 'Par', value: 'fname' },
    ],"items":_vm.historique,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"id","show-expand":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"dense":""},on:{"update:expanded":function($event){_vm.expanded=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function({headers,item}){return [_c('td',{attrs:{"colspan":headers.length}},[_c('historique-detail',{attrs:{"data":item.data,"view":item.type}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }