<template>
  <div>
    syncToken {{this.$store.state.sync.syncToken}}<br>
    firstSyncCompleted {{this.$store.state.sync.firstSyncCompleted}}<br>
    isSyncing {{this.$store.state.sync.isSyncing}}<br>
    <v-btn v-on:click="sync">SYNC</v-btn>
    <v-data-table
    dense
    :headers="headers"
    :items="Object.values(db.patient)"
    :items-per-page="5"
    class="elevation-1"
  ></v-data-table>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Tests',
  data () {
      return {
        headers: [
          {
            text: 'Id',
            align: 'start',
            sortable: false,
            value: 'telephone',
          },
          { text: 'Nom', value: 'nom' },
        ],
      }
    },
    computed: {
      ...mapGetters([
      'db',
    ]),
    },
    methods: {
      sync() {
        this.$store.dispatch('sync')
      }
    }
    
}
</script>
