<template>
  <v-container fluid>
    <h3>Paiements</h3>
    <v-data-table :headers="[
      { text: 'Id', value: 'id'},
      {
        text: 'Date',
        align: 'start',
        sortable: true,
        value: 'timestamp',
        width: 110
      },
      { text: 'Total', value: 'total' },
      { text: 'Méthode', value: 'description',width: 130 },
      { text: 'Desc', value: 'notes_et_desc',width: 130  },
      { text: 'Par', value: 'updated_by.fname' },
      { text: 'Actions', value: 'actions', sortable: false },
    ]" :items="paiements" :items-per-page="-1" :hide-default-footer="true" class="elevation-1" dense>
      <template v-slot:item.timestamp="{ item }">
        <span class="gray--text">{{ item.timestamp }}</span>
      </template>
      <template v-slot:item.notes_et_desc="{ item }">
        <span class="green--text" v-if="item.desc_ajustement">{{ item.desc_ajustement }}
        </span>
        <span class="gray--text">
          {{ item.notes }}
          
        </span>
      </template>
      <template  v-slot:item.actions="{ item }">
        <v-chip v-if="item.description == 'credit-clover' && item.montant==0 && !item.has_been_refunded" x-small color="red" text-color="white">
        Échec
      </v-chip>
      <v-chip x-small color="orange" text-color="white" v-if="item.description == 'credit-clover' && item.clover_info!='' && item.has_been_refunded">
        Remboursé
      </v-chip>
        <v-tooltip top v-if="item.description == 'credit-clover' && item.clover_info!=''">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small > mdi-code-json </v-icon>
            </template>
            <pre v-html="item.clover_info"></pre>
        </v-tooltip>
        <v-tooltip top v-if="item.description === 'credit-clover' && !item.has_been_refunded && item.montant>0">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="openDialogRemboursement(item)"> mdi-restart </v-icon>
          </template>
          <span>Rembourser</span>
        </v-tooltip>
        <v-tooltip top v-if="item.description != 'credit-clover'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="goToEditerPaiement(item.id)"> mdi-pencil </v-icon>
          </template>
          <span>Editer</span>
        </v-tooltip>
        <v-tooltip top v-if="item.description == 'credit-clover' && item.montant<0">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="goToEditerRemboursement(item)"> mdi-pencil </v-icon>
          </template>
          <span>Editer</span>
        </v-tooltip>
        
        <v-tooltip top v-if="item.description != 'credit-clover'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small v-bind="attrs" v-on="on" @click="supprimerPaiement(item.id)"> mdi-delete </v-icon>
          </template>
          <span>Supprimer</span>
        </v-tooltip>
        
      </template>
    </v-data-table> 
    <v-dialog v-model="dialogRemboursement" width="500">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card :loading="loadingDialog">
          <v-card-text>
            <v-spacer></v-spacer>
            <p class="text-h5 text--primary">Rembourser paiement</p>
            <v-text-field
                autofocus
                outlined
                dense
                readonly
                v-model="selected_paiement.montant"
                type="number"
                prefix="$"
                label="Montant total du remboursement"
                required
              ></v-text-field>
              <v-select
                  dense
                  required
                  outlined
                  v-model="selected_paiement.reason"
                  :readonly="selected_paiement.edit"
                  :items="[
                    'requested_by_customer','duplicate','fraudulent'
                  ]"
                  :rules="[v => !!v || 'Raison requis!']"
                  label="Raison clover"
                ></v-select>
                <v-textarea
                  label="Notes"
                  auto-grow
                  outlined
                  v-model="selected_paiement.notes"
                ></v-textarea>
            <v-btn v-if="!selected_paiement.edit" color="primary" class="mx-1" small v-on:click="saveRemboursement()" dense v-shortkey="['ctrl', 'g']"
              @shortkey="saveRemboursement()">Enregistrer un remboursement clover [Ctrl+g]</v-btn>
              <v-btn v-if="selected_paiement.edit" color="primary" class="mx-1" small v-on:click="modifierRemboursement()" dense v-shortkey="['ctrl', 'g']"
              @shortkey="modifierRemboursement()">Enregistrer modification [Ctrl+g]</v-btn>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import $axios from "../plugins/axios";

export default {
  props: ["patient_id"],
  data() {
    return {
      loading: false,
      paiements: [],
      selected_paiement: {},
      loadingDialog: false,
      valid: false,
      dialogRemboursement: false,
    };
  },
  created: function () {

    this.loadPaiements(this.patient_id);
  },
  watch: {
    patient_id: function (newVal) {
      this.message = "";
      this.loadPaiements(newVal);
    },
  },
  computed: {
    ...mapGetters(["employeArray","user"]),
  },
  methods: {
    async loadPaiements(patient_id) {
      try {
        this.loading = true;
        let data = await $axios.get(
          process.env.VUE_APP_API_URL + "/paiements/" + patient_id
        );
        this.paiements = data.data.map((el) => ({
          ...el,
          timestamp: this.toDate(el.timestamp),
          total: this.formatPrice(el.total),
          montant: el.total,
          clover_info : this.prettifyJson(el.clover_info),
          updated_by: this.employeArray.find((elm) => elm.id == el.updated_by),
        }));
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },
    goToEditerPaiement(paiement_id) {
      this.$router.push(`/facturation/${this.$route.params.patient_id}/paiement/${paiement_id}`)
    },
    openDialogRemboursement(paiement) {
      this.selected_paiement = {
        edit : false,
        id: paiement.id,
        notes : '',
        montant: paiement.montant
      }
      this.dialogRemboursement = true;
    },
    async supprimerPaiement(paiement_id) {
      try {
        if (confirm("Supprimer paiement?") == true) {
          let resp = await $axios.post(process.env.VUE_APP_API_URL + `/paiement/${paiement_id}/delete`);
          if (typeof resp.data.msg != "undefined") {
            alert(resp.data.msg)
          }
        }
      } catch (error) {
        alert(error)
      } finally {
        this.loadPaiements(this.$route.params.patient_id)
      }
    },
    async saveRemboursement() {
        try {
          if (this.$refs.form.validate()) {
            this.loadingDialog = true;
            let data = {
              user_id: this.user.id,
              paiement_id: this.selected_paiement.id,
              reason: this.selected_paiement.reason,
              notes: this.selected_paiement.notes
            }
            let resp = await $axios.post(
              process.env.VUE_APP_API_URL + "/clover/refund/",data
            );
            this.loadingDialog = false;
              if (resp.data.error == true) {
                alert(resp.data.message);
              }else{
                this.dialogRemboursement = false;
                alert("Remboursement effectué!"); 
                this.$emit("refresh-patient-info");
              }
          }
          
        } catch (error) {
          alert(error);
        }finally {
          this.loadingDialog = false;
        }
    },
    async modifierRemboursement(){
      try {
          if (this.$refs.form.validate()) {
            this.loadingDialog = true;
            let data = {
              id: this.selected_paiement.id,
              notes : this.selected_paiement.notes
            }
            let resp = await $axios.post(
              process.env.VUE_APP_API_URL + "/clover/refund/edit",data
            );
            this.loadingDialog = false;
              if (resp.data.error == true) {
                alert(resp.data.message);
              }else{
                this.dialogRemboursement = false;
                alert("Remboursement mis à jour!"); 
                this.$emit("refresh-patient-info");
              }
          }
        } catch (error) {
          alert(error);
        }finally {
          this.loadingDialog = false;
        }
    },
    goToEditerRemboursement(paiement){
      this.selected_paiement = Object.assign({},{
        edit: true,
        id: paiement.id,
        reason: paiement.refund_raison,
        notes : paiement.notes,
        montant : paiement.montant
      })
      this.dialogRemboursement = true;
    },
    prettifyJson(json) {
      if(json!=null){
        return JSON.stringify(JSON.parse(json), null, "\t")
      }
      return "";
      
  }


  },
};
</script>