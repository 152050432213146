import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import "@/plugins/dayjs";
import IdleVue from "idle-vue";
import * as VueGoogleMaps from "vue2-google-maps";
import ZebraPlugin from "./plugins/zebraPrinter";

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

Vue.use(ZebraPlugin);
Vue.use(require("vue-shortkey"));
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAT6aP-guhxFCL0POyc0Wnj17VWNoFviBk",
    libraries: "places",
  },
});

Vue.filter("formatPrice", require("./helpers/filters/prettifyPrice"));
Vue.filter("toDate", require("./helpers/filters/toDate"));
Vue.filter("toDateTime", require("./helpers/filters/toDateTime"));
Vue.filter("booleanToString", require("./helpers/filters/booleanToString"));
Vue.filter("capitalising", function (data) {
  var capitalized = [];
  data.split(" ").forEach((word) => {
    capitalized.push(
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
  });
  return capitalized.join(" ");
});

Vue.mixin({
  methods: {
    formatPrice: (amount) => {
      const options = { style: "currency", currency: "USD" };
      const numberFormat = new Intl.NumberFormat("en-US", options);
      return numberFormat.format(amount);
    },
    formatDate: (date) => {
      return this.$date(date).format("YYYY-MM-DD");
    },
    formatDateTime: (date) => {
      return this.$date(date).format("YYYY-MM-DD H[h]mm");
    },
    toDate: (value, default_val = "") => {
      if (typeof value === "undefined" || value == null || value === "") {
        return default_val;
      } else {
        if (value.includes("T")) {
          return value.split("T")[0];
        } else {
          return value.split(" ")[0];
        }
      }
    },
    getNextMonth(debut) {
      let nextMonth = this.$date.utc(debut.format("YYYY-MM-DD"));
      nextMonth = nextMonth.add(1, "month");
      nextMonth = nextMonth.subtract(1, "day");
      return nextMonth;
    },
    getPatientCycle(foyer, patient) {
      if (patient.date_ouverture_compte != null) {
        let debut_cycle = this.$date.utc(patient.date_ouverture_compte);
        return this.getCycleFacturation(
          foyer.debut_cycle_facturation,
          debut_cycle
        );
      }
      return null;
    },
    getCycleFacturation(debut_cycle_facturation, debut_cycle) {
      var list = [];
      let date_cycle = this.$date.utc(debut_cycle.format("YYYY-MM-DD")).set("date", debut_cycle_facturation);
      if(date_cycle>debut_cycle){
        let fin_cycle = date_cycle.subtract(1, "day").format("YYYY-MM-DD")
        list.push({
          text: debut_cycle.format("YYYY-MM-DD")+ " - "+ fin_cycle,
          debut: debut_cycle.format("YYYY-MM-DD"),
          fin: fin_cycle,
        });
      }
      let last_completed_cycle = null;
      var aujourdhui = this.$date.utc();
      while (date_cycle < aujourdhui) {
        var date_fin_cycle = this.getNextMonth(date_cycle);
        if (date_fin_cycle > aujourdhui) {
          list.push({
            text: date_cycle.format("MMMM YYYY") + " (INCOMPLET)",
            debut: date_cycle.format("YYYY-MM-DD"),
            fin: aujourdhui.format("YYYY-MM-DD"),
          });
          if (last_completed_cycle == null) {
            last_completed_cycle = {
              text: date_cycle.format("MMMM YYYY"),
              debut: date_cycle.format("YYYY-MM-DD"),
              fin: date_fin_cycle.format("YYYY-MM-DD"),
            };
          }
          break;
        } else {
          last_completed_cycle = {
            text: date_cycle.format("MMMM YYYY"),
            debut: date_cycle.format("YYYY-MM-DD"),
            fin: date_fin_cycle.format("YYYY-MM-DD"),
          };
          list.push({
            text: date_cycle.format("MMMM YYYY"),
            debut: date_cycle.format("YYYY-MM-DD"),
            fin: date_fin_cycle.format("YYYY-MM-DD"),
          });
        }
        date_cycle = date_cycle.add(1, "months");
      }
      list.reverse();
      return {
        list: list,
        last_completed_cycle: last_completed_cycle,
      };
    },
    
  },
});

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 300000,
  startAtIdle: false,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  onIdle() {
    store.dispatch("idleInterface");
  },
}).$mount("#app");
