<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto">
          <v-card-text>
            <p class="text-h4 text--primary">
              {{ this.patient.prenom }} {{ this.patient.nom }}
              <v-chip color="green" text-color="white">DDN : {{
                this.patient.ddn | toDate("-")
              }}</v-chip>
              <v-chip color="green" text-color="white">Foyer : {{
                  this.foyer.nom_reflex
                }}</v-chip>
              <v-chip color="green" text-color="white">App : {{
                  this.patient.appt
                }}</v-chip>
            </p>
            <autocomplete-patient
                  :patientsArray="patientsArray"
                  :searchHistory="[]"
                  :label="'Sélectionner un patient à comparer'"
                  @patient-selected="onPatientSelected"
                  ref="autocomplete_registre"
                  v-if="!isProd"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-select :items="old_versions" item-text="version" item-value="version" v-model="old_version" label="Version"
          v-on:change="compareRegistre()"></v-select>
      </v-col>
      <v-col cols="6">
        <v-select :items="new_versions" item-text="version" item-value="version" v-model="new_version" label="Version"
          v-on:change="compareRegistre()"></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-checkbox v-model="show_diff" label="Afficher ancien diff"></v-checkbox>
      </v-col>
      <v-col cols="6">
        <template>
          <v-alert v-if="new_registre != null && new_registre?.version != 'actuel'" type="warning">Attention, vous ne
            comparez pas avec la version actuelle</v-alert>
          <v-alert v-if="new_registre?.diff == null && new_registre?.version == 'actual' && old_registre?.version != null"
            type="warning">Aucun changement depuis la version {{ old_registre.version }}</v-alert>
          <v-alert v-if="new_registre?.diff != null && new_registre?.version == 'actual'" type="warning">Présence de
            changements non signés</v-alert>
        </template>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <registre
          type="gauche"
          :patient_id="patient.id"
          :registre_changement="old_registre"
          :show_diff="show_diff"
          :loading="loading"
          :version="old_version"
        />
      </v-col>
      <v-col cols="6" >
        <registre
          type="droite"
          :patient_id="patient.id"
          :registre_changement="new_registre"
          :show_diff="true"
          :loading="loading"
          :version="new_version"
          @signer_registre="signerRegistre()"
        />
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-simple-table>
            <tbody>
              <tr v-for="(item, index) in new_versions" :key="index">
                <template v-if="item.version != 'actuel' && item.version>=old_version && (new_version!='actuel' ? item.version<=new_version : true)">
                  <td>{{ `Version ${item.version} signée par ${item.signee_par_nom} le ${toDate(item.signee_date)}` }}
                  </td>
                  <td>
                    <v-btn color="primary" class="mx-1" small v-on:click="openPreuveLegale(item.version)" dense>Preuve
                      légale</v-btn>
                  </td>
                </template>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import $axios from "../plugins/axios";
import Registre from "../components/Registre.vue";
import AutocompletePatient from '../components/AutocompletePatient.vue';

export default {
  components: {
    registre: Registre,
    AutocompletePatient
  },
  data() {
    return {
      patient: {},
      foyer: {},
      new_versions: [],
      old_versions: [],
      new_version: "actuel",
      old_version: 0,
      old_registre: null,
      new_registre: null,
      show_diff: false,
      loading: false,
      derniere_version_id: 0,
      test_new_patient_id: null,
      isProd: false
    };
  },
  watch: {
    "$route.params.patient_id": function (id) {
      this.test_new_patient_id = null;
      if(!this.isProd){
        this.$refs.autocomplete_registre.clear();
      }
      this.loadData(id);
      this.test_new_patient_id = null;
    },
  },
  computed: {
    
    ...mapGetters(["db", "decoded",'patientsArray','user']),
  },
  methods: {
    toDate(value) {
      if (!value) return '';
      const separator = '-'
      const date = new Date(value);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}${separator}${month}${separator}${day}`;
    },
    async loadData(id) {
      this.patient = Object.assign(
        {},
        this.patient,
        this.db.patient[typeof id != 'undefined' ? id : this.$route.params.patient_id]
      );
      this.foyer = Object.assign(
        {},
        this.foyer,
        this.db.foyer[this.patient.foyer_id]
      );
      this.new_version = 'actuel';
      this.old_version = 0;
      this.new_versions = [];
      this.old_versions = [];
      await this.loadVersion();
      await this.compareRegistre();
    },
    async loadVersion() {
      let response = await $axios.get(process.env.VUE_APP_API_URL + `/versions_registre?pharmacie_id=${this.decoded.succursale.id}&patient_id=${this.patient.id}`);
      if(this.test_new_patient_id!=null){
        this.old_versions = [{
          version: 'actuel'
        }].concat(response.data);
        response = await $axios.get(process.env.VUE_APP_API_URL + `/versions_registre?pharmacie_id=${this.decoded.succursale.id}&patient_id=${this.test_new_patient_id}`);
        this.new_versions = [{
          version: 'actuel'
        }].concat(response.data);
      }else{
        this.new_versions = [{
          version: 'actuel'
        }].concat(response.data);
        this.old_versions = response.data;
        if(this.old_versions.length>0 && this.old_version==0){
          this.old_version = this.old_versions[0].version
        }
      }
      
    },
    async compareRegistre() {
      if(this.new_version!=null && this.old_version!=null){
        this.loading = true;
        try {
          let response = null 
          if(this.test_new_patient_id!=null){
            response = await $axios.get(process.env.VUE_APP_API_URL + `/compare_registre?pharmacie_id=${this.decoded.succursale.id}&patient_id=${this.patient.id}&old=${this.old_version}&new=${this.new_version}&test_new_patient_id=${this.test_new_patient_id}`);
          }else{
            response = await $axios.get(process.env.VUE_APP_API_URL + `/compare_registre?pharmacie_id=${this.decoded.succursale.id}&patient_id=${this.patient.id}&old=${this.old_version}&new=${this.new_version}`);
          }
          if (response.data.error) {
            alert(response.data.error)
            this.old_registre = null;
            this.new_registre = null;
            this.derniere_version_id = 0;
          } else {
            this.old_registre = response.data.old_registre;
            this.new_registre = response.data.new_registre;
            this.derniere_version_id = response.data.dernier_version_signee_id;
          }

        }
        catch (err) {
          console.error(err)
        }
        this.loading = false;
      }
      
    },
    openPreuveLegale(version) {
      window.open(process.env.VUE_APP_API_URL + `/registre_preuve_legale?pharmacie_id=${this.decoded.succursale.id}&patient_id=${this.patient.id}&version=${version}`);
    },
    async signerRegistre() {
      if (this.new_registre != null && this.user?.type=='pharmacien' && this.user?.licence!='') {

        const params = {
          pharmacie_id: this.decoded.succursale.id,
          patient_id: this.patient.id,
          licence: this.user.licence,
          registre: this.new_registre.registre,
          diff: this.new_registre.diff,
          derniere_version_id: this.derniere_version_id,
          nom_patient: `${this.patient.prenom} ${this.patient.nom}`
        }
        this.loading = true;
        try{
          let resp = await $axios.post(
            process.env.VUE_APP_API_URL + "/signer_registre",
            params
          );
          if(typeof resp.data.error !=undefined && resp.data.error){
            alert(resp.data.error);
            this.loading = false;
          }else{
            this.old_version = resp.data.version;
            await this.loadVersion();
            await this.compareRegistre();
            this.loading = false;
          }
        }catch(error) {
          console.error(error);
          this.loading = false;
        }
      }

    },
    async onPatientSelected(patientId) {
      if(patientId!=this.patient.id){
        this.test_new_patient_id = patientId
        this.new_version = 'actuel';
        this.old_version = 'actuel';
        this.new_versions = [];
        this.old_versions = [];
        await this.loadVersion();
        await this.compareRegistre();
      }
    },
  },
  mounted: function () {
    this.loadData();
    this.isProd = process.env.VUE_APP_APP_URL == '//codex.rxweb.ca';
  },
};
</script>
<style lang="scss">
.table-registre {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.8em;
  tbody {
    border: 4px solid black;
  }

  td,
  th {
    text-align: center;
    height: 30px;
    border: 1px solid #1e1e1e;
  }
  p{
    margin-bottom: 0;
    height: 30px;
  }
}

tbody {
  tr {
    padding: 0;
  }
}
.deleted-item {
  background-color: #e62626c9;
  text-decoration: line-through;
}
.updated-item {
  background-color: orange;
}
.added-item {
  background-color: rgb(106, 255, 113);
}
</style>
