module.exports =(function (value,default_val='') {
    if(typeof value==='undefined'|| value==null || value===''){
        return default_val;
    }else{
        if(value){
            return 'Oui'
        }else{
            return 'Non'
        }
    }
    
    
})