<template>
    <v-container fluid>
      <h3>Historique des changements</h3>
      <v-data-table
        :headers="[
          { text: 'Information', value: 'type' },
          {
            text: 'Date de mise à jour',
            align: 'start',
            sortable: true,
            value: 'timestamp',
          },
          { text: 'Par', value: 'fname' },
        ]"
        :items="historique"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="id"
        show-expand
        class="elevation-1"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        dense
      >
        <template v-slot:expanded-item="{headers,item}">
            <td :colspan="headers.length">
              <historique-detail :data="item.data" :view="item.type" />
            </td>
        </template>
      </v-data-table>
    </v-container>
  </template>
  
  <script>
  import $axios from "../plugins/axios";
  import HistoriqueDetail from "../components/HistoriqueDetail";
  
  export default {
    props: ["patient_id"],
    components: {
      HistoriqueDetail
    },
    data() {
      return {
        expanded: [],
        singleExpand: true,
        loading: false,
        historique: [],
        sortBy: "timestamp",
        sortDesc : true
      };
    },
    created: function () {
      this.loadHistorique(this.patient_id);
    },
    watch: {
      patient_id: function (newVal) {
        this.loadHistorique(newVal);
      },
    },
    methods: {
      async loadHistorique(patient_id) {
        //charger liste historique
        try {
          this.loading = true;
          let data = await $axios.get(
            process.env.VUE_APP_API_URL + "/historique/" + patient_id
          );
          this.historique = data.data.map((el) => ({
            ...el,
            data : JSON.parse(el.data),
            timestamp : el.updated_at.substring(0,16).replace(':','h'),
          }));
        } catch (error) {
          alert(error);
        } finally {
          this.loading = false;
        }
      },
    },
  };
  </script>
  
  <style>
  </style>