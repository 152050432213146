<template>
  <v-container fluid>
    <v-row >
      <v-col cols="12">
        <v-card outlined>
          <v-card-text>
            <v-form @submit="onSubmit" onSubmit="return false;">
              <v-text-field
                :loading="loading"
                v-model="searchTerm"
                label="Rechercher par Nom, DIN, McKesson Number ou GTIN"
                placeholder="Nom, DIN, McKesson Number ou GTIN produit"
                outlined
                autofocus
              >
                <v-icon slot="append" type="submit"> mdi-magnify </v-icon>
              </v-text-field>
            </v-form>
            <v-checkbox
                dense
                outlined
                v-model="print"
                :label="`Imprimer étiquette prix automatiquement`"
              ></v-checkbox>
            <v-subheader>{{ productResults.length }} résultats</v-subheader>
          </v-card-text>
        </v-card>
        <br/>
        <v-data-table
            dense
            :headers="headers"
            :items="productResults"
            :items-per-page="100"
            :loading="loading"
            :footer-props="{ 'items-per-page-options': [50, 100, -1] }"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            item-key="id"
            show-expand
            class="elevation-1"
          >
            <template v-slot:[`item.description`]="{ item }">
              <label>{{ item.item_description }}</label>
              <br/>
              <v-chip v-if="item.product_status == 'discontinue_par_grossiste'" color="error" x-small label class="mr-1">Discontinué par grossiste</v-chip>
              <v-chip v-if="item.product_status == 'discontinue_par_manufacturier'" color="warning" x-small label class="mr-1">Discontinué par manufacturier</v-chip>
            </template>
            <template v-slot:[`item.din`]="{ item }">
              {{ item.din!=0 ? item.din : '' }}
            </template>
            <template v-slot:[`item.prixCoutant`]="{ item }">
              {{ item.regular_unit_price }} $
            </template>
            <template v-slot:[`item.prixVendant`]="{ item }">
              <v-tooltip color="black" bottom lazy v-if="item.prix_special!=item.suggested_retail_price">
                <template #activator="{ on }">
                  <v-chip v-on="on" color="orange" text-color="white">{{ item.prix_special }} $</v-chip>
                </template>
                <p>Prix vendant spécial : {{ item.prix_special }} $</p>
                <p class="mb-0">Prix vendant McKesson : {{ item.suggested_retail_price }} $</p>
              </v-tooltip>
              
              <p v-if="item.prix_special==item.suggested_retail_price">{{ item.suggested_retail_price }} $</p>
            </template>
            
            <template v-slot:expanded-item="{headers,item}">
              <td :colspan="headers.length">
                
                <v-card-text>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>Mise à jour le </v-list-item-title>
                            <v-list-item-subtitle>{{ item.last_update }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>Importé le</v-list-item-title>
                            <v-list-item-subtitle>{{ item.updated_at }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>product_family_group</v-list-item-title>
                            <v-list-item-subtitle>{{ item.product_family_group }} - {{ item.sub_categorie }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>drug_code</v-list-item-title>
                            <v-list-item-subtitle>{{ item.drug_code }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>controlled_drug</v-list-item-title>
                            <v-list-item-subtitle>{{ item.controlled_drug }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>product_format</v-list-item-title>
                            <v-list-item-subtitle>{{ item.product_format }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>supplier_product_format</v-list-item-title>
                            <v-list-item-subtitle>{{ item.supplier_product_format }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>product_strength</v-list-item-title>
                            <v-list-item-subtitle>{{ item.product_strength }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>product_family_group</v-list-item-title>
                            <v-list-item-subtitle>{{ item.product_family_group }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>product_form</v-list-item-title>
                            <v-list-item-subtitle>{{ item.product_form }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>generic_product_number</v-list-item-title>
                            <v-list-item-subtitle>{{ item.generic_product_number }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>generic_product_description</v-list-item-title>
                            <v-list-item-subtitle>{{ item.generic_product_description }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>product_therapeutic_class</v-list-item-title>
                            <v-list-item-subtitle>{{ item.product_therapeutic_class }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>supplier_product_number</v-list-item-title>
                            <v-list-item-subtitle>{{ item.supplier_product_number }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>suggested_retail_price</v-list-item-title>
                            <v-list-item-subtitle>{{ item.suggested_retail_price }} $</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>tps</v-list-item-title>
                            <v-list-item-subtitle>{{ item.tps }} </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>tvq</v-list-item-title>
                            <v-list-item-subtitle>{{ item.tvq  }} </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>



                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>supplier_code</v-list-item-title>
                            <v-list-item-subtitle>{{ item.supplier_code }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>regular_unit_price</v-list-item-title>
                            <v-list-item-subtitle>{{ item.regular_unit_price }} $</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>regular_case_price</v-list-item-title>
                            <v-list-item-subtitle>{{ item.regular_case_price }} $</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>regular_case_price_discount</v-list-item-title>
                            <v-list-item-subtitle>{{ item.regular_case_price_discount }} $</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>msq</v-list-item-title>
                            <v-list-item-subtitle>{{ item.msq }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>unit_quantity_case</v-list-item-title>
                            <v-list-item-subtitle>{{ item.base_price_scp }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>special_unit_price</v-list-item-title>
                            <v-list-item-subtitle>{{ item.special_unit_price }} $</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>min_quantity_sup</v-list-item-title>
                            <v-list-item-subtitle>{{ item.min_quantity_sup }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>special_case_price</v-list-item-title>
                            <v-list-item-subtitle>{{ item.special_case_price }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>base_price_scp</v-list-item-title>
                            <v-list-item-subtitle>{{ item.generic_product_number }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>unit_modality</v-list-item-title>
                            <v-list-item-subtitle>{{ item.unit_modality }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>case_modality</v-list-item-title>
                            <v-list-item-subtitle>{{ item.case_modality }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>gtin_unit</v-list-item-title>
                            <v-list-item-subtitle>{{ item.gtin_unit }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>gtin_pack</v-list-item-title>
                            <v-list-item-subtitle>{{ item.gtin_pack }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>gtin_unit</v-list-item-title>
                            <v-list-item-subtitle>{{ item.gtin_case }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-card-text>
                    <v-card-actions>
                      <v-row no-gutters>
                        <v-btn color="primary" class="mx-1" small v-on:click="printProductLabel(item)">Imprimer étiquettes</v-btn>
                      </v-row>
                    </v-card-actions>
                  
              </td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small @click="printProductLabel(item)"> mdi-printer </v-icon>
            </template>

          </v-data-table>
      </v-col>
      
    </v-row>
  </v-container>
</template>

<script>
import $axios from "../../plugins/axios";

export default {
  name: "Produit",
  data() {
    return {
      expanded: [],
      singleExpand: true,
      searchTerm: "",
      loading: false,
      productResults: [],
      print : false,
      headers: [
        { text: "Code MCK", value: "item_number" },
        { text: "Nom", value: "description" },
        { text: "Catégorie", value: "sub_categorie" },
        { text: "DIN", value: "din" },
        { text: "Prix coutant", value: "prixCoutant", align:"center" },
        { text: "Prix vendant", value: "prixVendant", align:"center" },
        { text: "GTIN", value: "gtin_unit" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      $axios
        .get(process.env.VUE_APP_API_URL + "/mckesson/catalogue/searchProduct?queryTerm=" + this.searchTerm)
        .then((response) => (this.productResults = response.data.products))
        .catch((err) => console.log(err))
        .finally(() => {
          if(this.productResults.length == 1 && this.print){
            this.printProductLabel(this.productResults[0]);
          }
          if(this.productResults.length >0){
            this.searchTerm = ''
          }
          this.loading = false;
        });
    },
    printProductList() {
      window.open(
        process.env.VUE_APP_API_URL +
        `/mckesson/printProductList?date_selection=${this.dateSelection}`
      );
    },
    printProductLabel(selectedItem){
      const montantStr = selectedItem.prix_special.toFixed(2); 
      const [dollars, centimes] = montantStr.split('.'); 
      let taxes = '';
      if(selectedItem.tps==1 && selectedItem.tvq==1){
        taxes = '+ TAXES';
      }else{
        if(selectedItem.tps==1){
          taxes = '+ TPS';
        }else if(selectedItem.tvq==1){
          taxes = '+ TVQ';
        }else{
          taxes = 'NON TAX';
        }
      }
      let costPrice = this.transformPriceToChar(selectedItem.regular_unit_price);
      const zpl= `^XA
^LT0
^PON
^PW400
^LT20
^FO240,50
^A0N,24
^FWN,0
^FD${taxes}
^FS
^FO220,0
^A0N,90
^FWN,1
^FD${dollars}
^FS
^FO220,0
^A0N,40
^FWN,0
^FD${centimes > 0 ? centimes : '00'}
^FS
^FX Box
^FO0,72^GB1095,60,60
^FS
^FWN,0
^FO15,80
^A0N,24
^FR
^FB380,2,0,L,0
^TBN, 380,60
^FD${selectedItem.item_description.trim()}
^FS
^FO30,140
^BY2
^BUN,15
^FD${this.getBarCode(selectedItem.gtin_unit)}
^FS
^FO385,140
^ACN,20,1
^FWN,1
^FDMCK ${selectedItem.item_number}
^FS
^FO385,165
^ACN,20,1
^FWN,1
^FD${costPrice}
^FS
^FO400,130
^ACN,20
^FWN,1
^FD
^FS
^XZ`
      console.log(zpl)
      this.$store.dispatch("printLabel",zpl);
    },
    transformPriceToChar(price) {
        const priceStr = price.toString();
        const mapping = {
          '1': 'U',
          '2': 'D',
          '3': 'T',
          '4': 'Q',
          '5': 'C',
          '6': 'X',
          '7': 'S',
          '8': 'H',
          '9': 'N',
          '0': 'Z'
        };
        let transformedPrice = '';
        for (let i = 0; i < priceStr.length; i++) {
          if (priceStr[i] === '.') {
            transformedPrice += '.';
          }else{
            transformedPrice += mapping[priceStr[i]];
          }
          
        }
        return transformedPrice;
    },
    getBarCode(gtin_code){
      if(gtin_code[0]=='0'){
        if(gtin_code[1]=='0'){
          return `^BUN,15^FD${gtin_code.substring(2,14)}`;
        }else{
          return `^BEN,15,Y,N^FD${gtin_code.substring(1,14)}`;
        }
      }else{
        return `^B2N,15^FD${gtin_code}`;
      }
    }
  },
};
</script>
