<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" md="6">
        <v-alert v-if="foyer.id == 0" dismissible type="error">Attention le patient n'est pas dans un foyer</v-alert>
        <v-alert v-if="patient.date_ouverture_compte == null" type="error">Ce compte n'est pas ouvert, indiquez une date
          d'ouverture</v-alert>
        <v-alert v-if="patient.date_fermeture_compte !== null" type="error">ATTENTION COMPTE FERMÉ LE
          {{ this.$date.utc(this.patient.date_fermeture_compte).format("YYYY-MM-DD") }}</v-alert>

        <v-card class="mx-auto" :loading="loading">
          <v-card-text>
            <p class="text-h4 text--primary">
              {{ patient.prenom }} {{ patient.nom }}
              <v-chip color="green" text-color="white">{{ foyer.nom_reflex }}</v-chip>
              <v-chip color="orange" text-color="white"> App : {{ patient.appt }}</v-chip>
            </p>
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Date ouverture compte</v-list-item-title>
                    <v-list-item-subtitle>{{
                      this.patient.date_ouverture_compte
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Solde ouverture compte</v-list-item-title>
                    <v-list-item-subtitle>{{
                      this.patient.solde_ouverture_compte
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Date fermeture compte</v-list-item-title>
                    <v-list-item-subtitle>{{
                      this.patient.date_fermeture_compte
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Carte de crédit</v-list-item-title>
                    <v-list-item-subtitle>{{ this.cc_format(this.patient.cc) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Carte de crédit expiration</v-list-item-title>
                    <v-list-item-subtitle>{{ this.cc_date_exp_format(this.patient.cc_exp) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Carte de crédit CVV</v-list-item-title>
                    <v-list-item-subtitle>{{ this.patient.cc_cvv }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Paiement pré-autorisé (PPA)</v-list-item-title>
                    <v-list-item-subtitle>{{
                      this.patient.ppa | booleanToString
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>PPA autorisation notes</v-list-item-title>
                    <v-list-item-subtitle>{{
                      this.patient.ppa_autorisation
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Courriel pour facturation</v-list-item-title>
                    <v-list-item-subtitle>{{
                      this.patient.facture_par_courriel_adresse
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Facture papier</v-list-item-title>
                    <v-list-item-subtitle>{{ this.patient.facture_methode }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Adresse pour poster facture</v-list-item-title>
                    <v-list-item-subtitle>{{ this.patient.facture_autre_adresse_ou_fax }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Mot de passe einterac</v-list-item-title>
                    <v-list-item-subtitle>{{ this.patient.password_einterac }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="8">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Note</v-list-item-title>
                    <v-list-item-subtitle>{{ this.patient.notes }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row no-gutters>
              <v-btn color="primary" class="mx-1" small v-on:click="goToFormPatient()" dense v-shortkey="['ctrl', 'o']"
                @shortkey="goToFormPatient()">Modifier patient [Ctrl+O]</v-btn>
              <v-btn color="primary" class="mx-1" small v-on:click="goToInscrirePaiement" dense v-shortkey="['ctrl', 'i']"
                @shortkey="goToInscrirePaiement()">Inscrire Paiement [Ctrl+I]</v-btn>
              <v-dialog v-model="dialogFacture" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" class="mx-1" small v-bind="attrs" v-on="on" dense v-shortkey="['ctrl', 'd']"
                    @shortkey="openDialogFacture()">Facture [Ctrl+D]
                  </v-btn>
                </template>

                <v-card>

                  <v-card-text>
                    <v-spacer></v-spacer>
                    <p class="text-h5 text--primary">Séléction date facture</p>
                    <v-select :items="this.monthList" item-text="text" item-value="debut" label="Mois facture"
                      v-model="dateFacture" outlined autofocus ref="selectMois"></v-select>
                    <v-btn color="primary" class="mx-1" small v-on:click="openRecusFacture" dense
                      v-shortkey="['ctrl', 'f']" @shortkey="openRecusFacture()">Générer facture [Ctrl+F]</v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-row>
            <v-row no-gutters>

              <v-btn :disabled="!cardValid" color="primary" class="mx-1" small v-on:click="openDialogClover" dense
                v-shortkey="['ctrl', 't']" @shortkey="openDialogClover()">Clover paiement [Ctrl+t]
              </v-btn>
              <v-dialog v-model="dialogClover" width="500">


                <v-form ref="formCloverPaiement" v-model="valid" lazy-validation>
                  <v-card :loading="loadingDialogClover">

                    <v-card-text>

                      <v-spacer></v-spacer>
                      <p class="text-h5 text--primary">Inscrire paiement</p>
                      <p class=" text--primary">Sur carte {{ this.cc_format(this.patient.cc) }}({{ this.patient.cc_cvv }})
                        {{ this.cc_date_exp_format(this.patient.cc_exp) }}</p>
                      <v-alert
                        v-if="((patient.date_ouverture_compte != null && patient.ppa && soldeCompte != transaction_amount) && soldeCompte > 0)"
                        type="error">Attention, la valeur entrée est différente du solde de l'état de compte.</v-alert>
                      <v-text-field autofocus outlined dense v-model="transaction_amount" type="number" prefix="$"
                        label="Montant" required :rules="amountRules" ref="transactionAmount"
                        v-on:keydown.enter.prevent=""></v-text-field>
                      <v-btn :disabled="!valid" color="primary" class="mx-1" small v-on:click="saveCloverPaiement" dense
                        v-shortkey="['ctrl', 'r']" @shortkey="saveCloverPaiement()">Enregistrer un paiement clover
                        [Ctrl+r]</v-btn>
                    </v-card-text>
                  </v-card>
                </v-form>
              </v-dialog>

              <v-btn color="primary" class="mx-1" small v-on:click="openEtatDecompte" dense v-shortkey="['ctrl', 'e']"
                @shortkey="openEtatDecompte()">État de compte [Ctrl+E]</v-btn>

              <v-dialog v-model="dialogImpot" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" class="mx-1" small v-bind="attrs" v-on="on" dense v-shortkey="['ctrl', 'g']"
                    @shortkey="openDialogRecuImpot()">Recu impôt [Ctrl+g]
                  </v-btn>
                </template>

                <v-card>

                  <v-card-text>
                    <v-spacer></v-spacer>
                    <p class="text-h5 text--primary">Séléction année</p>
                    <v-select :items="this.yearList" item-text="text" item-value="val" label="Année impôt"
                      v-model="anneeImpot" outlined ref="selectAnnee" autofocus></v-select>
                    <v-btn color="primary" class="mx-1" small v-on:click="openRecusImpot" dense v-shortkey="['ctrl', 'r']"
                      @shortkey="openRecusImpot()">Afficher reçu impôt [Ctrl+R]</v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-btn color="primary" class="mx-1" small v-on:click="goToEnvoyerMail" dense v-shortkey="['ctrl', 'c']"
                @shortkey="goToEnvoyerMail()">Envoyer courriel [Ctrl+C]</v-btn>

            </v-row>
          </v-card-actions>
        </v-card>
        <v-row>
          <paiementComp @refresh-patient-info="loadPatientInfo" ref="paiementComp"
            :patient_id="$route.params.patient_id"></paiementComp>
        </v-row>
      </v-col>

      <v-col cols="6" md="6">
        <v-card style="height: 50%" :loading="loadingPdfEtatCompte">
          <iframe v-show="!loadingPdfEtatCompte" @load="onLoadEtatCompte" ref="iframeEtatCompte" width="100%" height="100%" frameborder="0"></iframe>
        </v-card>
        <br />
        <v-card style="height: 50%" :loading="loadingPdfCopieEtatCompte">
            <iframe @load="onLoadCopieEtatCompte" v-show="!loadingPdfCopieEtatCompte" ref="iframeCopieEtatCompte" width="100%" height="100%" frameborder="0"></iframe>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <historiqueComp ref="historiqueComp" :patient_id="$route.params.patient_id"></historiqueComp>
    </v-row>
    <v-navigation-drawer app right clipped>
      <piece-jointe :patient_id="$route.params.patient_id"></piece-jointe>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import $axios from "../plugins/axios";
import PieceJointe from "../components/PieceJointe.vue";
import Paiements from "../components/Paiements.vue";
import HistoriqueChangement from "../components/HistoriqueChangement.vue";

export default {
  components: {
    PieceJointe,
    paiementComp: Paiements,
    historiqueComp: HistoriqueChangement,
  },
  data() {
    return {
      loading: false,
      loadingPdfEtatCompte: true,
      loadingPdfCopieEtatCompte: true,
      loadingPaiements: false,
      paiements: [],
      paiement: {
        methode: "credit",
      },
      servicesData: [],
      selected: [],
      filtrer_services: false,
      dialogFacture: false,
      dialogImpot: false,
      headers: [
        { text: "ServicesID", value: "ServicesID" },
        { text: "Date", value: "Date", sortable: true, align: "start" },
        { text: "#Rx", value: "#Rx" },
        { text: "Produit", value: "Description" },
        { text: "Percu", value: "Percu" },
        { text: "TPS", value: "TPS", sortable: true },
        { text: "TVQ", value: "TVQ", sortable: true },
      ],
      monthList: [],
      yearList: [],
      dateFacture: null,
      anneeImpot: null,
      transaction_amount: 0,
      dialogClover: false,
      loadingDialogClover: false,
      amountRules: [
        (v) => !!v || 'Montant invalide!',
        (v) => v > 0 || 'Montant invalide!',
        (v) => {
          const regex = /^\d+(\.\d{1,2})?$/;
          return regex.test(v) || "Pas plus de deux chiffres après la virgule!";
        }
      ],
      valid: true,
      keyEtatCompteComp: 1,
      keyCopieEtatCompteComp  :1,
      soldeCompte: 0,
    };
  },
  watch: {
    patient(newValue, oldValue) {
      console.log(newValue.id, oldValue.id)
      this.loadPatientInfo();
    }
  },
  computed: {
    foyer: function () {
      return this.db.foyer[this.patient.foyer_id]
        ? this.db.foyer[this.patient.foyer_id]
        : {
          id: 0,
          nom_reflex: "Aucun foyer",
          debut_cycle_facturation: 1,
          facturation_mensuelle: 0,
        };
    },
    patient: function () {
      let patient = this.db.patient[this.$route.params.patient_id];
      if (typeof patient != undefined) {
        patient.fullname = patient.prenom + " " + patient.nom,
          patient.searchTerm = [patient.nom, patient.prenom, patient.ddn, patient.appt, patient.password_einterac]
            .join(" ")
            .toLowerCase();
        this.$store.commit("addSearch", patient);
      }

      return patient;
    },
    cardValid: function () {
      return this.patient.cc != "" && this.patient.cc_cvv != "" && this.patient.cc_exp != "";
    },
    facture: function () {
      let months_to_substract =
        parseInt(this.$date().format("D")) < this.foyer.debut_cycle_facturation
          ? 1
          : 2;
      let debut = this.$date()
        .subtract(months_to_substract, "month")
        .date(this.foyer.debut_cycle_facturation)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .format("YYYY-MM-DD");
      let fin = this.$date(debut)
        .add(1, "month")
        .subtract(1, "second")
        .format("YYYY-MM-DD");
      return { debut: debut, fin: fin };
    },
    total: function () {
      let total = this.selected.length
        ? this.selected.map((el) => el.Percu).reduce((a, b) => a + b)
        : 0;
      return Math.round(total * 100) / 100 + " $";
    },
    services: function () {
      return this.filtrer_services
        ? this.servicesData.filter((el) =>
          this.$date(el.Date).isBetween(
            this.facture.debut,
            this.facture.fin,
            null,
            "[]"
          )
        )
        : this.servicesData;
    },
    ...mapGetters(["db", "employeArray", "decoded", "user"]),
  },
  methods: {
    onLoadEtatCompte(){
      this.loadingPdfEtatCompte = false;
    },
    onLoadCopieEtatCompte(){
      this.loadingPdfCopieEtatCompte = false;
    },
    getCopieEtatDeCompteHREF: function () {
      let fin = this.getDateFinFacturation();
      return `${process.env.VUE_APP_API_URL}/etat_facturation?pharmacie_id=${this.decoded.succursale.id}&fin=${fin}&patient_id=${this.$route.params.patient_id}&etat_de_compte=false&copie_etat_de_compte=true`;
    },
    getEtatDeCompteHREF: function () {
      let fin = this.getDateFinFacturation();
      return `${process.env.VUE_APP_API_URL}/etat_facturation?pharmacie_id=${this.decoded.succursale.id}&fin=${fin}&patient_id=${this.$route.params.patient_id}&etat_de_compte=true&copie_etat_de_compte=false`;
    },
    goToEnvoyerMail() {
      this.$router.push(
        `/sendmail/${this.$route.params.patient_id}`
      );
    },
    openEtatDecompte() {
      window.open(this.etatDeCompteHREF);
    },
    goToInscrirePaiement: function () {
      this.$router.push(
        `/facturation/${this.$route.params.patient_id}/paiement/`
      );
    },
    goToFormPatient: function () {
      this.$router.push(
        '/facturation/modifier/' + this.$route.params.patient_id
      )
    },
    async loadServices() {
      try {
        this.loading = true;
        let data = await $axios.get(
          process.env.VUE_APP_API_URL + "/reflex_services/" + this.patient.id
        );
        this.servicesData = data.data;
        this.servicesData.forEach((el) => {
          if (
            this.$date(el.Date).isBetween(
              this.facture.debut,
              this.facture.fin,
              null,
              "[]"
            )
          ) {
            this.selected.push(el);
          }
        });
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },
    openRecusImpot() {
      if (this.anneeImpot != null) {
        var annee = this.anneeImpot.val ?? this.anneeImpot;
        var debut = `${annee}-01-01`;
        var fin = `${annee}-12-31`;
        var aujourdhui = this.$date.utc();
        if(annee==aujourdhui.get("year")){
          fin = aujourdhui.format("YYYY-MM-DD");
        }
        window.open(
          process.env.VUE_APP_API_URL +
          `/recus_impot/patient?pharmacie_id=${this.decoded.succursale.id}&debut=${debut}&fin=${fin}&patient_id=` +
          this.$route.params.patient_id
        );
      }
    },
    openRecusFacture() {
      if (this.dateFacture != null) {
        let date_ouverture_compte = this.$date.utc(this.patient.date_ouverture_compte);
        let periodeFacture = this.monthList.find(x=>x.debut==this.dateFacture)
        var debut,fin = ""
        if(periodeFacture!=undefined){
          debut = periodeFacture.debut
          fin = periodeFacture.fin
        }else{
          var date_debut = this.$date.utc(this.dateFacture);
          var date_fin = this.getNextMonth(date_debut);
          var today = this.$date.utc();
          debut = date_debut < date_ouverture_compte ? date_ouverture_compte.format("YYYY-MM-DD") : date_debut.format("YYYY-MM-DD");
          fin = date_fin.format("YYYY-MM-DD");
          if (date_fin > today) {
            fin = today.format("YYYY-MM-DD");
          }
        }
        
        window.open(
          process.env.VUE_APP_API_URL +
          `/etat_facturation?pharmacie_id=${this.decoded.succursale.id}&debut=${debut}&fin=${fin}&patient_id=${this.$route.params.patient_id}&facture=true`
        );
      }

    },
    openDialogFacture() {
      if (typeof this.$refs.selectMois != "undefined") {
        this.$refs.selectMois.focus();
      }
      this.dialogFacture = true;
    },
    openDialogRecuImpot() {
      if (typeof this.$refs.selectAnnee != "undefined") {
        this.$refs.selectAnnee.focus();
      }
      this.dialogImpot = true;
    },
    loadMonthList() {
      if (this.patient.date_ouverture_compte != null) {
        let cycle_facturation = this.getPatientCycle(this.foyer, this.patient)
        this.monthList = cycle_facturation.list
        if(cycle_facturation.last_completed_cycle!=null){
          this.dateFacture = cycle_facturation.last_completed_cycle.debut
        }else{
          this.dateFacture = this.patient.date_ouverture_compte
        }
        
      }
    },
    loadYearList() {
      let date_ouverture_compte = this.$date.utc(this.patient.date_ouverture_compte);
      var aujourdhui = this.$date.utc();
      var end = aujourdhui.get('year');
      var start = date_ouverture_compte.get('year');
      if (end < start) {
        end = start;
      }
      let list = []
      for (var year = end; year >= start; year--) {
        list.push({
          val : year,
          text : year == end ? `${year} (INCOMPLET)` : year
        });
      }
      this.yearList = list
      this.anneeImpot = this.yearList.length>1 ? this.yearList[1] : this.yearList[0]
    },
    cc_format(value) {
      var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
      var matches = v.match(/\d{4,16}/g);
      var match = matches && matches[0] || ''
      var parts = []

      for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4))
      }

      if (parts.length) {
        return parts.join(' ')
      } else {
        return value
      }
    },

    cc_date_exp_format(value) {
      var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
      var matches = v.match(/\d{2,4}/g);
      var match = matches && matches[0] || ''
      var parts = []

      for (let i = 0, len = match.length; i < len; i += 2) {
        parts.push(match.substring(i, i + 2))
      }

      if (parts.length) {
        return parts.join('/')
      } else {
        return value
      }
    },
    openDialogClover() {
      this.transaction_amount = this.patient != null && this.patient.date_ouverture_compte != null && this.patient.ppa && this.soldeCompte > 0 ? this.soldeCompte : 0;
      this.dialogClover = true;
    },
    async saveCloverPaiement() {
      try {
        if (this.$refs.formCloverPaiement.validate()) {
          this.loadingDialogClover = true;
          let response = await $axios.post(
            process.env.VUE_APP_API_URL + "/clover/paiement/",
            Object.assign({}, {
              amount: this.transaction_amount,
              user_id: this.user.id,
              patient_id: this.$route.params.patient_id,
            }));
          this.dialogClover = false;
          if (response.data.error) {
            alert(response.data.message)
          }
          this.loadPatientInfo();
          this.loadingDialogClover = false;
        }
      } catch (error) {
        alert(error);
      } finally {
        this.dialogClover = false;
        this.loadingDialogClover = false;
      }
    },
    loadPatientInfo() {
      this.$refs.paiementComp.loadPaiements(this.$route.params.patient_id);
      this.loadingPdfEtatCompte = true;
      this.loadingPdfCopieEtatCompte = true;
      this.etatDeCompteHREF = this.getEtatDeCompteHREF();
      this.loadMonthList();
      this.loadYearList();
      this.getSoldeCompte();
      const iframeCopieEtatCompte = this.$refs.iframeCopieEtatCompte;
      if (iframeCopieEtatCompte) {
        iframeCopieEtatCompte.onload = this.onLoadCopieEtatCompte;
        iframeCopieEtatCompte.src = this.getCopieEtatDeCompteHREF(); 
      }

      const iframeEtatCompte = this.$refs.iframeEtatCompte;
      if (iframeEtatCompte) {
        iframeEtatCompte.onload = this.onLoadEtatCompte;
        iframeEtatCompte.src = this.getEtatDeCompteHREF(); 
      }
    },
    async getSoldeCompte() {
      this.loading = true;
      try {
        if (this.patient != null && this.patient.date_ouverture_compte != null && this.patient) {
          let response = await $axios.get(process.env.VUE_APP_API_URL + "/facturation/solde_compte/" + this.patient.id);
          if (response.data.error) {
            alert(response.data.message)
          } else {
            this.soldeCompte = response.data.solde;
          }
        } else {
          this.soldeCompte = 0;
        }

      } catch (error) {
        alert(error);
      }
      this.loading = false;
    },
    getDateFinFacturation(){
      var date = this.$date.utc();
      let debut_cycle_facturation = this.$date.utc(date.get('year') + "-" + date.get('month') + "-" + this.foyer.debut_cycle_facturation);
      if (date < debut_cycle_facturation) {
        debut_cycle_facturation = debut_cycle_facturation.subtract(1, "month")
      }
      let date_fin_facturation = this.$date.utc(debut_cycle_facturation);
      date_fin_facturation = date_fin_facturation.add(1, "month")
      if (date < date_fin_facturation) {
        date_fin_facturation = date_fin_facturation.subtract(1, "month")
      }
      if (date < date_fin_facturation) {
        date_fin_facturation = date_fin_facturation.subtract(1, "month")
      }
      date_fin_facturation = date_fin_facturation.subtract(1, "day")
      return date_fin_facturation.format("YYYY-MM-DD");
    }
  },
  updated() {
    if (this.dialogClover) {
      const element = this.$refs.transactionAmount
      element.focus();
      this.$refs.formCloverPaiement.validate()
    }
  },
  mounted: function () {
    this.loadServices();
    this.loadPatientInfo();
  },
};
</script>
