<template>
  <div>
    <v-row no-gutters>
      <v-col cols="3">
        <v-card outlined>
          <v-card-text>
            <v-btn icon x-small v-on:click="show_advanced = !show_advanced"
              ><v-icon>mdi-cog</v-icon></v-btn
            >
            <div v-if="show_advanced">
              <v-btn text color="primary" v-on:click="updateFoyers"
                >Mettre à jour foyers</v-btn
              >
              <br /><br />
              <v-text-field
                outlined
                dense
                clearable
                v-model="debut_update_patients"
                type="date"
                max="2999-01-01"
                label="Début"
              ></v-text-field>
              <v-btn text color="primary" v-on:click="updatePatients"
                >Mettre à jour patients</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
        <v-spacer></v-spacer>
        <form-facturation />
      </v-col>

      <v-col cols="9">
        <v-container fluid>
          <!-- {{patientsArray}} -->
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import $axios from "../plugins/axios";
import FormFacturation from "./Facturation/FormFacturation";

export default {
  name: "Facturation",
  components: {
    FormFacturation,
  },
  data() {
    return {
      patient_id: 1,
      show_advanced: false,
      debut_update_patients: this.$date.utc().startOf("year").format("YYYY-MM-DD"),
    };
  },
  computed: {
    ...mapGetters(["db", "patientsArray"]),
  },
  methods: {
    loadPatient: function () {
      this.$router.push("/facturation/" + this.patient_id);
    },
    async updateFoyers() {
      $axios
        .get(process.env.VUE_APP_API_URL + "/reflex_foyers_update/")
        .then((response) => alert(response.data))
        .catch((err) => alert(err));
    },
    async updatePatients() {
      $axios
        .get(
          `${process.env.VUE_APP_API_URL}/reflex_patients_update?debut=${this.debut_update_patients}`
        )
        .then((response) => alert(response.data))
        .catch((err) => alert(err));
    },
  },
};
</script>
