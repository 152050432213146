<template>
  <v-row>

    <v-col cols="3">
    <v-card outlined>
      <v-card-text>
        <v-date-picker
              v-model="dates"
              range
              :first-day-of-week="1"
              locale="fr"
              :max="finSemaine"
              :on-change="updateRange()"
            ></v-date-picker>
      </v-card-text>
    </v-card>
  </v-col>
  <v-col cols="9">
    <v-card outlined>
      <v-card-text>
        <iframe :src="urlRapport" :key="keyEtatCompteComp" width="100%" height="600" frameborder="0">
        </iframe>
      </v-card-text>
    </v-card>
  </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      debut: '',
      fin : '',
      finSemaine : '',
      dates : null,
      loading: false,
    };
  },
  mounted(){
    var date = this.$date.utc();
    this.finSemaine = date.endOf('week').format("YYYY-MM-DD");
    this.setRange(date);
  },
  computed: {
    urlRapport : function (){
return `${process.env.VUE_APP_API_URL}/clover/weekly_report?pharmacie_id=${
          this.decoded.succursale.id}&debut=${this.debut}&fin=${this.fin}`
    },
    ...mapGetters(["decoded"]),
  },
  methods: {
    updateRange: function (){
      if(this.dates!=null && this.dates.length==1){
        let date = this.$date.utc(this.dates[0])
        this.setRange(date);
      }
    },
    setRange(date){
      this.debut = date.startOf('week').format("YYYY-MM-DD");
      this.fin = date.endOf('week').format("YYYY-MM-DD");
      this.dates = [date.startOf('week').format("YYYY-MM-DD"),date.endOf('week').format("YYYY-MM-DD")]
    }
  },
};
</script>
