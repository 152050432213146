<template>
    <v-container fluid>
      <v-row class="text-center">      
        <v-col cols="12">        
          <v-card :loading="isLoadingGetAvailablePrinters">
            <v-card-title>New Printers</v-card-title>
            <v-data-table
              :headers="availablePrintersHeaders"
              :items="availablePrinters"
            >
            </v-data-table>
            <v-card-actions>
              <v-btn @click="fetchPrinterList">fetchPrinterList</v-btn>
              <v-btn @click="updateDefaultPrinter">updateDefaultPrinter</v-btn>
            </v-card-actions>
          </v-card>

            <v-textarea label="Label" v-model="zpl"></v-textarea>

            <v-btn @click="print()">Print</v-btn>
  
            
         
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: "Imprimante",
    data() {
      return {
        availablePrinters: [],
        items: [],
        produit: {},
        zpl: '',
        isLoadingGetAvailablePrinters: false,
        availablePrintersHeaders: [
            { text: 'Name', value: 'name' },
            { text: 'DeviceType', value: 'deviceType'},
            { text: 'uid', value: 'uid' },
            { text: 'provider', value: 'provider' },
            { text: 'Connection', value: 'connection' },
            { text: 'Manufacturer', value: 'manufacturer' },
            { text: 'Status', value: 'status'},
            { text: 'Message', value: 'message'},
          ],
      };
    },
    methods: {
      async fetchPrinterList() {
        this.isLoadingGetAvailablePrinters = true
        try {
          this.availablePrinters = await this.$zebraPlugin.getPrinters();
          console.log("Imprimantes trouvées:", this.availablePrinters);
        } catch (error) {
          console.error("Erreur:", error);
        }
        finally{
          this.isLoadingGetAvailablePrinters = false
        }
      },
      async updateDefaultPrinter(){
        var defaultPrinter = await this.$zebraPlugin.getDefaultPrinter();
        this.$store.dispatch("updateDefaultPrinter",defaultPrinter);
      },
      async print() {
        try {
          await this.$store.dispatch("printLabel",this.zpl);
        } catch (error) {
          console.log(error)
        }
      },
    }
  };
  </script>