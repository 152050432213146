<style>
 .lightSmoke {
    background-color: #dddddd !important;
    border-color: #dddddd !important;
 }
</style>
<template>
  <v-container fluid>
    <v-row no-gutter>
      <v-col cols="6">
        <h3>Lots</h3>
            <v-data-table
          :headers="[
            { text: 'Statut', value: 'status', sortable: false , width: '100px' },
            {
              text: 'Date de création',
              value: 'creationDate',
              sortable: false,
              width: '160px'
            },
            { text: 'Date renouv.', value: 'workingDate', sortable: false , width: '105px'},
            { text: 'Plage de date', value: 'Period', sortable: false , width: '150px'},
            {
              text: 'Rx attente',
              value: 'ShouldIncludeWaitingPrescriptions',
              sortable: false,
              width: '100px'
            },
            { text: 'Etiquette', value: 'ShouldPrintLabels', sortable: false, width: '100px' },
            {
              text: 'Réclamation',
              value: 'ShouldTransmitClaims',
              sortable: false,
               width: '100px'
            },
            { text: 'Reçu', value: 'ShouldTransmitClaims', sortable: false , width: '100px'},
            { text: 'Exécuté par', value: 'Employee', sortable: false, width: '100px' },
            {
              text: 'Pharmacien en service',
              value: 'Pharmacist',
              sortable: false,
              width: '100px'
            },
          ]"
          :items="batchs"
          :hide-default-footer="false"
          :options.sync="options"
          :server-items-length="totalBatchs"
          :loading="loading"
          :items-per-page="options.itemsPerPage"
          v-model="selectedRow"
          item-key="Id"
          @page-count="pageCount = $event"
          class="elevation-1"
          dense
        >
          <template v-slot:item="{ item }">
            <tr
              :class="selectedRow == item.Id ? 'lightSmoke' : ''"
              @click="rowClicked(item)"
            >
              <td>
                <v-chip :color="getStatusColor(item.status)" outlined label>
                  {{ getStatus(item.status) }}
                </v-chip>
              </td>
              <td>{{ item.creationDate }}</td>
              <td>{{ item.workingDate }}</td>
              <td>{{ item.Period }}</td>
              <td>
                <v-icon
                  v-if="item.ShouldIncludeWaitingPrescriptions === true"
                  color="green"
                >
                  mdi-check
                </v-icon>
              </td>
              <td>
                <v-icon v-if="item.ShouldPrintLabels === true" color="green">
                  mdi-check
                </v-icon>
              </td>
              <td>
                <v-icon v-if="item.ShouldTransmitClaims === true" color="green">
                  mdi-check
                </v-icon>
              </td>
              <td>
                <v-icon v-if="item.ShouldTransmitClaims === true" color="green">
                  mdi-check
                </v-icon>
              </td>
              <td>{{ item.Employee }}</td>
              <td>{{ item.Pharmacist }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="6">
          <h3>Détail lot</h3>

          <iframe
            :src="batchDetailLink"
            width="100%"
            height="600"
            frameborder="0"
            >
        </iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import $axios from "../../plugins/axios";
import { mapGetters } from "vuex";

export default {
  props: ["patient_id"],
  data() {
    return {
      loading: false,
      batchs: [],
      selectedRow: [],
      options: {
        itemsPerPage: 15,
        page: 1,
      },
      pageCount: 1,
      totalBatchs: 0,
      batchDetailLink : ''
    };
  },
  created: function () {
    this.loadBatchs();
  },
  watch: {
    options: {
      handler() {
        this.loadBatchs();
      },
      deep: true,
    },
    
  },
  computed : {
        ...mapGetters([ "decoded"]),
  },
  methods: {
    async loadBatchs() {
      try {
        const { page, itemsPerPage } = this.options;

        this.loading = true;
        let response = await $axios.get(
          process.env.VUE_APP_API_URL +
            "/lots?itemsPerPage=" +
            itemsPerPage +
            "&page=" +
            page 
        );
        this.batchs = response.data.data.map((el) => ({
          ...el,
        }));
        this.totalBatchs = response.data.totalItems;
        this.pageCount = response.data.totalPage;
        this.options.itemsPerPage = response.data.itemsPerPage;
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },

    getStatusColor(status) {
      switch (status) {
        case 1:
          return "orange";
        case 2:
          return "green";
        case 3:
          return "blue";
        default:
          return "grey";
      }
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "-";
        case 2:
          return "-";
        case 3:
          return "Completé";
        default:
          return "-";
      }
    },
    rowClicked(row) {
      this.toggleSelection(row.Id);
      this.loadDetails(row.Id);
    },
    toggleSelection(keyID) {
      this.selectedRow = [keyID];
    },
    async loadDetails(BatchId){
        this.batchDetailLink = `${process.env.VUE_APP_API_URL}/lot?pharmacie_id=${this.decoded.succursale.id}&batch_id=${BatchId}`
    }
  },
};
</script>
