<template>
  <v-row no-gutters>
    <v-col cols="2">
      <v-card outlined>
        <v-card-text>
          <v-autocomplete
            autofocus
            v-model="succursale"
            :items="succusalesArray"
            item-text="id"
            item-value="id"
            outlined
            dense
            label="Succursale"
          ></v-autocomplete>

          <v-text-field
            outlined
            dense
            clearable
            v-model="debut"
            type="date"
            label="Début"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            clearable
            v-model="fin"
            type="date"
            label="Fin"
          ></v-text-field>
          <v-btn outlined v-on:click="onLoad"> Générer </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col> </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Tests",
  data() {
    return {
      succursale: 1,
      debut: '',
      fin : '',
      filtrer: true,
      pdfLink: "",
      loading: false,
    };
  },
  mounted(){
    var date = new Date();
    this.debut = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString("en-CA");
    this.fin = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString("en-CA");
  },
  methods: {
    onLoad: function () {
      window.open(
        `${process.env.VUE_APP_API_URL}/pdf/profit?succursale=${
          this.db.succursales[this.succursale].id
        }&debut=${this.debut}&filtrer=true&fin=${this.fin}`,
        "_blank"
      );
    },
  },
  computed: {
    ...mapGetters(["db", "succusalesArray"]),
  },
};
</script>
