export default {
  state: {
    searchHistory: [],
    isImportingCatalogue: false,
    appLoading: false,
  },
  getters: {
    searchHistory: (state) => state.searchHistory,
    isImportingCatalogue: (state) => state.isImportingCatalogue,
    appLoading: state => state.appLoading,
  },
  actions: {
    showLoader({ commit }) {
      commit("showLoader");
    },
    hideLoader({ commit }) {
      commit("hideLoader");
    },
    reinitializeSearch({ commit }) {
      commit("reinitializeSearch");
    },
    addSearch({ commit }, patient) {
      if (typeof patient != undefined) {
        commit("addSearch", patient);
      }
    },
    updateImportCatalogue({ commit }, value) {
      commit("updateImportCatalogue", value);
    },
  },
  mutations: {
    hideLoader(state) {
      state.appLoading = false
    },
    showLoader(state) {
      state.appLoading = true
    },
    reinitializeSearch(state) {
      state.searchHistory = [];
    },
    addSearch(state, patient) {
      let list = state.searchHistory;
      const index = list.findIndex((object) => {
        return object.id === patient.id;
      });
      if (index != -1) {
        list.splice(index, 1);
      }
      if (list.length > 9) {
        list.splice(-1);
        state.searchHistory = [patient, ...list];
      } else {
        state.searchHistory = [patient, ...list];
      }
    },
    updateImportCatalogue(state, value) {
      state.isImportingCatalogue = value;
    },
  },
};
