var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutter":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('h3',[_vm._v("Lots")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
          { text: 'Statut', value: 'status', sortable: false , width: '100px' },
          {
            text: 'Date de création',
            value: 'creationDate',
            sortable: false,
            width: '160px'
          },
          { text: 'Date renouv.', value: 'workingDate', sortable: false , width: '105px'},
          { text: 'Plage de date', value: 'Period', sortable: false , width: '150px'},
          {
            text: 'Rx attente',
            value: 'ShouldIncludeWaitingPrescriptions',
            sortable: false,
            width: '100px'
          },
          { text: 'Etiquette', value: 'ShouldPrintLabels', sortable: false, width: '100px' },
          {
            text: 'Réclamation',
            value: 'ShouldTransmitClaims',
            sortable: false,
             width: '100px'
          },
          { text: 'Reçu', value: 'ShouldTransmitClaims', sortable: false , width: '100px'},
          { text: 'Exécuté par', value: 'Employee', sortable: false, width: '100px' },
          {
            text: 'Pharmacien en service',
            value: 'Pharmacist',
            sortable: false,
            width: '100px'
          },
        ],"items":_vm.batchs,"hide-default-footer":false,"options":_vm.options,"server-items-length":_vm.totalBatchs,"loading":_vm.loading,"items-per-page":_vm.options.itemsPerPage,"item-key":"Id","dense":""},on:{"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{class:_vm.selectedRow == item.Id ? 'lightSmoke' : '',on:{"click":function($event){return _vm.rowClicked(item)}}},[_c('td',[_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"outlined":"","label":""}},[_vm._v(" "+_vm._s(_vm.getStatus(item.status))+" ")])],1),_c('td',[_vm._v(_vm._s(item.creationDate))]),_c('td',[_vm._v(_vm._s(item.workingDate))]),_c('td',[_vm._v(_vm._s(item.Period))]),_c('td',[(item.ShouldIncludeWaitingPrescriptions === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_vm._e()],1),_c('td',[(item.ShouldPrintLabels === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_vm._e()],1),_c('td',[(item.ShouldTransmitClaims === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_vm._e()],1),_c('td',[(item.ShouldTransmitClaims === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_vm._e()],1),_c('td',[_vm._v(_vm._s(item.Employee))]),_c('td',[_vm._v(_vm._s(item.Pharmacist))])])]}}]),model:{value:(_vm.selectedRow),callback:function ($$v) {_vm.selectedRow=$$v},expression:"selectedRow"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h3',[_vm._v("Détail lot")]),_c('iframe',{attrs:{"src":_vm.batchDetailLink,"width":"100%","height":"600","frameborder":"0"}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }