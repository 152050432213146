<template>
  <v-app>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-card-text>
            <v-form ref="form" v-on:submit.prevent="onSubmit">
              <v-select
                v-model="succursale"
                :items="succursales"
                item-value="id"
                item-text="id"
                outlined
                label="Succursale"
              ></v-select>
              <v-text-field
                autofocus
                v-model="password"
                type="password"
                label="Mot de passe"
                placeholder="Mot de passe"
                required
                outlined
              ></v-text-field>
              <v-card-actions>
                <v-spacer />
                <v-btn type="submit" color="primary" class="mr-4"
                  >Connexion</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-overlay :value="this.appLoading">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-row>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Login",
  data: () => ({
    succursale: "saules",
    succursales: [
      {
        id: "saules",
      },
      {
        id: "oasis",
      },
      {
        id: "paton",
      }
    ],
    password: "",
  }),
  computed: {
    ...mapGetters(["appLoading"])
  },
  methods: {
    async onSubmit() {
      this.$store.dispatch("showLoader");
      try {
        await this.$store.dispatch("login", {
          succursale: this.succursale,
          password: this.password,
        });
        this.$router.push("/");
      } catch (error) {
        this.$store.dispatch("hideLoader");
        alert("Erreur: " + error.response.data.msg);
      }
    },
  },
};
</script>