<template>
  <div>
    <v-row no-gutters>
      <v-col cols="3">
        <v-card outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-autocomplete
                v-model="foyer"
                :items="foyers"
                item-value="id"
                item-text="nom_reflex"
                no-data-text="AUCUN RÉSULTAT"
                outlined
                solo
                dense
                hide-details
                label="Foyer"
                :rules="[v => !!v || 'Foyer est requis']"
                required
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.nom_reflex }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <br/>

              <v-text-field
                required
                outlined
                dense
                clearable
                v-model="date1"
                type="date"
                max="2999-01-01"
                label="1e date renouvellement"
                :rules="[v => !!v || '1e date renouvellement est requis']"
              ></v-text-field>

              <v-text-field
                outlined
                dense
                clearable
                v-model="date2"
                type="date"
                max="2999-01-01"
                label="2e date renouvellement"
              ></v-text-field>

              <v-text-field
                outlined
                dense
                clearable
                v-model="date3"
                type="date"
                max="2999-01-01"
                label="3e date renouvellement"
              ></v-text-field>

              <v-text-field
                outlined
                dense
                clearable
                v-model="date4"
                type="date"
                max="2999-01-01"
                label="4e date renouvellement"
              ></v-text-field>

              <v-text-field
                required
                outlined
                dense
                clearable
                v-model="dureeTraitement"
                type="number"
                label="Durée de traitement"
                :rules="[v => !!v || 'Durée de traitement est requis']"
              ></v-text-field>

            </v-form>
            <v-btn :disabled="!valid" color="primary" class="mr-4" @click="openReport">Générer</v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="9">
        <v-container fluid>
          <!-- Autres contenus ici -->
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import $axios from "../plugins/axios";
import { mapGetters } from "vuex";

export default {
  name: "VerificationRenouvellement",
  data() {
    return {
      foyer: null,
      date1: null,
      date2: null,
      date3: null,
      date4: null,
      dureeTraitement: 7,
      valid: false,
      foyers: []
    };
  },
  computed: {
    ...mapGetters(["decoded", "db"]),
  },
  mounted() {
    $axios.get(process.env.VUE_APP_API_URL + "/foyer?show_active=true").then((response) => {
      this.foyers = response.data;
    });
  },
  methods: {
    openReport() {
      if (this.$refs.form.validate()) {
        let params = new URLSearchParams();
        params.append('pharmacie_id', this.decoded.succursale.id);
        params.append('foyer_id', this.foyer);
        params.append('duree_traitement', this.dureeTraitement);

        if (this.date1) params.append('date1', this.date1);
        if (this.date2) params.append('date2', this.date2);
        if (this.date3) params.append('date3', this.date3);
        if (this.date4) params.append('date4', this.date4);

        let url = `${process.env.VUE_APP_API_URL}/verification_renouvellement?${params.toString()}`;
        window.open(url);
      }
    },
  }
};
</script>
