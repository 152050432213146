<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <v-card outlined :loading="loading">
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-alert
                type="error"
                v-if="
                  patient.facture_par_courriel_adresse != '' &&
                  patient.facture_par_courriel_adresse != destinataire
                "
              >
                Attention, ceci n'est pas le courriel par défaut qui est
                {{ this.patient.facture_par_courriel_adresse }}
              </v-alert>
              <v-alert
                type="warning"
                v-if="
                  patient.facture_par_courriel_adresse1 != '' &&
                  sujet != default_sujet
                "
              >
                Attention le sujet a été modifié
              </v-alert>
              <v-text-field
                outlined
                dense
                v-model="destinataire"
                type="text"
                label="Pour"
                required
                :rules="emailRules"
                counter="500"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                v-model="sujet"
                type="text"
                label="Sujet"
                required
                :rules="[(v) => !!v || 'Champ requis']"
              ></v-text-field>
              <v-autocomplete
                v-model="selected"
                :items="types"
                item-text="text"
                item-value="val"
                outlined
                solo
                dense
                hide-details
                required
                multiple
                v-on:change="updateSelected()"
                :rules="[(v) => !!v || 'Champ requis']"
              ></v-autocomplete>
              <br />
              <v-textarea
                label="Message"
                auto-grow
                outlined
                v-model="message"
                required
              ></v-textarea>
              <p class="text-h4 text--primary">
                <v-btn
                  color="error"
                  class="mr-4"
                  v-shortkey="['ctrl', 'z']"
                  @shortkey="goToPagePatient()"
                  @click="goToPagePatient"
                  >Annuler [ Ctrl + z]</v-btn
                >
                <v-btn
                  :disabled="!valid"
                  color="success"
                  v-shortkey="['ctrl', 's']"
                  @shortkey="envoyerMail()"
                  @click="envoyerMail"
                  class="mr-4"
                  >Envoyer [ Ctrl + s]</v-btn
                >
              </p>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col m-2 cols="6" style="height: 100vh">
        <v-card :loading="loading" style="overflow-y: auto; height: 66%">
          <iframe
              :src="etat_facturation_pdf_url"
              frameborder="0"
              width="100%"
              height="100%"
            >
          </iframe>
        </v-card>
        <br />
        <v-card :loading="loading" style="overflow-y: auto; height: 33%">
          <v-card-text>
            <pdf
                v-for="(item, index) in selected_pdfs"
                :key="index"
                :src="item.url">
            </pdf>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import $axios from "../../plugins/axios";
import pdf from 'vue-pdf'
export default {
  name: "SendMail",
  components: {
    pdf,
  },
  data() {
    return {
      valid: true,
      loading: false,
      patient: {},
      foyer: {},
      destinataire: "",
      sujet: "",
      default_sujet: "",
      types: [],
      selected: [],
      selected_pdfs: [],
      etat_facturation_pdf_url: '',
      message: ``,
      emailRules: [
        (v) => !!v || "Destinataire requis",
        (v) => /.+@.+\..+/.test(v) || "E-mail invalide",
        (v) => v.length<=500 || "E-mail invalide",
        (v) => !/\s+$/.test(v) || "E-mail invalide",
      ],
    };
  },
  mounted() {
    this.patient = Object.assign(
      {},
      this.patient,
      this.db.patient[this.$route.params.patient_id]
    );
    let name = this.user != null ? this.user.fname + " " + this.user.lname : ""
    this.patient.nom = this.patient.nom.replace(/ *\([^)]*\) */g, "");
    this.patient.prenom = this.patient.prenom.replace(/ *\([^)]*\) */g, "");
    this.destinataire = this.patient.facture_par_courriel_adresse.trim();
    this.default_sujet = `Pharmacie - Facturation ${this.patient.nom.trim()} ${this.patient.prenom.trim()}`
    this.sujet = `Pharmacie - Facturation ${this.patient.nom.trim()} ${this.patient.prenom.trim()}`;
    this.foyer = this.db.foyer[this.patient.foyer_id];
    this.message = `Bonjour,  
Voici l'état de compte pour ${this.patient.nom} ${this.patient.prenom}.

Merci et bonne journée! 

${name},
Pharmacie Dorian Margineanu Inc
téléphone: 514-384-6177
télécopieur: 514-384-2171
comptabilite@pharmacielaval.ca`;
    this.loadAutocomplete();
  },
  computed: {
    ...mapGetters(["db","user", "decoded", "getPatientById"])
  },
  methods: {
    updateSelected() {
      let selected_list = this.selected_pdfs
      selected_list.forEach(el=>{
        if (this.selected.indexOf(el.val) == -1) {
          const index_val = this.selected_pdfs.findIndex(
            (object) => {
              return object.val === el.val;
            }
          );
          this.selected_pdfs.splice(index_val, 1);
        }
      });
      this.selected.forEach(el=>{
        let item = this.types.find(x=>x.val == el)
         if(typeof el.val == 'undefined'){
          item = this.types.find(x => x.val == el);
        }else{
          item = this.types.find(x => x.val == el.val);
        }
        if(typeof item !="undefined" && (item.type == "liste_medicament" || item.type == "recu_impot")){
          if(this.selected_pdfs.find(x=>x.val == item.val) == null){
            this.selected_pdfs.push(item)
          }
        }
      });
      let url = this.getEtatCompteFactureUrl()
      if(this.etat_facturation_pdf_url != url){
        this.etat_facturation_pdf_url = url
      }
      
    },
     async envoyerMail() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true;
          let pdfs = []
          if(this.etat_facturation_pdf_url!=''){
            pdfs.push({
              filename : 'Etat de compte + Facture.pdf',
              url : this.etat_facturation_pdf_url
            })
          }
          this.selected_pdfs.forEach(el=>{
            if(el.type=='liste_medicament' || el.type=='recu_impot'){
              pdfs.push({
                filename : el.filename,
                url: el.url
              })
            }
          })
          $axios.post(
            process.env.VUE_APP_API_URL+ "/sendmail",
            Object.assign(
              {},
              {
                pour: this.destinataire,
                sujet: this.sujet,
                message: this.message,
                pdfs: pdfs
              }
            )
          ).then(function (resp) {
             if (resp.data.error == true) {
              alert(resp.data.msg);
            }
          })
          .catch(function (error) {
            alert("Email non envoyé! : "+error);
          })
          this.goToPagePatient();
        }
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },
    goToPagePatient() {
      this.$router.push("/facturation/" + this.$route.params.patient_id);
    },
    loadAutocomplete() {
      let types = [];
      let selected = [{
          type: 'etat_de_compte',
          val: 'etat_de_compte',
          text: "Etat de compte",
      }];
      if (this.patient.date_ouverture_compte != null) {
        let date_ouverture_compte = this.$date.utc(this.patient.date_ouverture_compte);
        let date_cycle = this.$date.utc(this.patient.date_ouverture_compte);
        date_cycle = date_cycle.set('date',this.foyer.debut_cycle_facturation);
        var aujourdhui = this.$date.utc();
        var list = [];
        let debut_cycle_facturation = this.$date.utc();
        debut_cycle_facturation = debut_cycle_facturation.set('date',this.foyer.debut_cycle_facturation);
        if (aujourdhui < debut_cycle_facturation) {
          debut_cycle_facturation = debut_cycle_facturation.subtract(1,'month')
        }
        let date_fin_facturation = this.$date.utc(debut_cycle_facturation.format("YYYY-MM-DD"));
        date_fin_facturation = date_fin_facturation.add(1,'month')
        if (aujourdhui < date_fin_facturation) {
          date_fin_facturation = date_fin_facturation.subtract(1,'month')
        }
        if (aujourdhui < date_fin_facturation) {
          date_fin_facturation = date_fin_facturation.subtract(1,'month')
        }
        date_fin_facturation = date_fin_facturation.subtract(1,'day')
        let fin = date_fin_facturation.format("YYYY-MM-DD");
        types.push(
          {
            text: "Etat de compte",
            val: 'etat_de_compte',
            type: 'etat_de_compte',
            fin : fin
          },
          {
            text: "Copie état de compte",
            val: 'copie_etat_de_compte',
            type: 'copie_etat_de_compte',
            fin : fin
          },
          {
            text: "Liste de médicaments",
            type: 'liste_medicament',
            val: 'liste_medicament',
            filename: 'liste_medicament.pdf',
            url : 'https://' +process.env.VUE_APP_API_URL +`/liste_medicament?pharmacie_id=${this.decoded.succursale.id}&patient_id=${this.patient.id}`
          }
        );
        let index = 1;
        while (date_cycle < aujourdhui) {
          var date_fin_cyle = this.$date.utc(date_cycle.format("YYYY-MM-DD"));
          date_fin_cyle = date_fin_cyle.add(1,'month')
          date_fin_cyle = date_fin_cyle.subtract(1,'day')
          let value = date_cycle < date_ouverture_compte ? date_ouverture_compte.format("YYYY-MM-DD") : date_cycle.format("YYYY-MM-DD") 
          if (date_fin_cyle > aujourdhui) {
            list.push({
              index: index,
              val: `facture_${value}`,
              text: "Facture " + value + " au " + aujourdhui.format("YYYY-MM-DD")+ " (INCOMPLET)",
              type: 'facture',
              debut: value,
              fin : aujourdhui.format("YYYY-MM-DD"),
            });
            break;
          } else {
            list.push({
              index: index,
              val: `facture_${value}`,
              text: "Facture " + value + " au " + date_fin_cyle.format("YYYY-MM-DD"),
              type: 'facture',
              debut: value,
              fin : date_fin_cyle.format("YYYY-MM-DD"),
            });
          }
          date_cycle = date_cycle.add(1,'month')
          index++
        }
        list.reverse();
        if (list.length > 1) {
          selected.push(list[1]);
        }
        var end = aujourdhui.get('year');
        var start = date_ouverture_compte.get('year');
        for (var year = end; year >= start; year--) {
          list.push({
            text:`Relevé d'impôt ${year}` + (aujourdhui.get('year') == year ? " (INCOMPLET)" : ""),
            type: 'recu_impot',
            val: `recu_impot_${year}`,
            filename: `recu_impot_${year}.pdf`,
            url: 'https://' +process.env.VUE_APP_API_URL +`/recus_impot/patient?pharmacie_id=${this.decoded.succursale.id}&debut=${year}-01-01&fin=${year}-12-31&patient_id=${this.$route.params.patient_id}`,
          });
        }
        types = types.concat(list);
      }
      this.types = types;
      this.selected = selected;
      this.updateSelected();
    },
    getEtatCompteFactureUrl(){
      let afficher_etat_compte = false
      let afficher_copie_etat_compte = false
      let factures = []
      let fin = null
      let max_index_facture = 0
      let min_index_facture = -1
      this.selected.forEach(el=>{
        let val = null
        if(typeof el.val == 'undefined'){
          val = this.types.find(x => x.val == el);
        }else{
          val = this.types.find(x => x.val == el.val);
        }
        if(val!= null){
          if(val.type=='etat_de_compte'){
            afficher_etat_compte = true
            fin = val.fin
          }else if(val.type=='copie_etat_de_compte'){
            afficher_copie_etat_compte = true
            fin = val.fin
          }else if(val.type=='facture'){
            factures.push({
              val : `${val.debut}_${val.fin}`,
              index : val.index
            })
            if(val.index>max_index_facture){
              max_index_facture = val.index
            }
            if(val.index<min_index_facture || min_index_facture== -1){
              min_index_facture = val.index
            }
          }
        }
      });
      if((min_index_facture+1)<max_index_facture){
        for(let idx=min_index_facture+1;idx<max_index_facture;idx++){
          let item = this.types.find(x=> x.type =='facture' && x.index == idx)
          if(item!=null && this.selected.indexOf(item.val) == -1 ){
            this.selected.push(item.val)
            factures.push({
              val : `${item.debut}_${item.fin}`,
              index : item.index
            })
          }
        }
      }
      factures.sort((a, b) => (a.index > b.index) ? 1 : -1)
      factures = factures.map(({ val }) => val)
      let afficher_facture = factures.length > 0 ? true : false
      let url = ''
      if(afficher_etat_compte || afficher_copie_etat_compte || afficher_facture){
        url = 'https://' +process.env.VUE_APP_API_URL +`/etat_facturation?pharmacie_id=${this.decoded.succursale.id}&patient_id=${this.$route.params.patient_id}&etat_de_compte=${afficher_etat_compte}&copie_etat_de_compte=${afficher_copie_etat_compte}`;
        if(afficher_etat_compte || afficher_copie_etat_compte){
          url = url + `&fin=${fin}`
        }
        if(factures.length>0){
          url = url + `&facture=true&factures=${factures}`
        }
      }
      return url
    },
  },
};
</script>
