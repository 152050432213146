export default {
  state: {
      defaultPrinter: {},
      isReadyToPrint: false,
      printerMessage: ''
  },
  getters: {
    isReadyToPrint: (state) => state.isReadyToPrint,
    defaultPrinter: (state) => state.defaultPrinter,
    printerMessage: (state) => state.printerMessage,
  },
  actions: {
    async updateDefaultPrinter({commit},printer) {
      try{
        const printerStatus = await printer.getStatus();
        commit('updateStatus',printerStatus.isPrinterReady(),printerStatus.getMessage())
        commit('updateDefaultPrinter',printer)
      }
      catch(error){
        commit('updateStatus',false,error)
      }
    },
    async printLabel({ state,commit },zpl) {
      try {
        state.defaultPrinter.isPrinterReady().then(function(){
          state.defaultPrinter.send(zpl);
          commit('updateStatus',true,"")
        }).catch(function(error){
            console.error(error);
            commit('updateStatus',false,error)
        })
      } catch (error) {
        commit('updateStatus',state.isReadyToPrint,error);
      }
    }
  },
  mutations: {
    updateDefaultPrinter(state,printer){
      state.defaultPrinter = printer;
    },
    updateStatus(state,status,errors=''){
      state.isReadyToPrint = status;
      state.printerErrors = errors;
    }
  },
};
