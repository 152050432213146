<template>
  <v-container>
    <v-card class="mx-auto my-4" :loading="loading">
      <v-card-text>
        <p class="text-h4 text--primary">
          {{ titre }}: {{ patient.prenom }} {{ patient.nom }}
          <v-btn
            color="error"
            v-shortkey="['ctrl', 'z']"
            @shortkey="goToListPage()"
            @click="goToListPage"
            class="mr-4"
            >Annuler [ Ctrl + z]</v-btn
          >
          <v-btn
            color="success"
            v-shortkey="['ctrl', 's']"
            @shortkey="updatePaiement()"
            @click="updatePaiement"
            class="mr-4"
            :disabled="!valid"
            >Sauvegarder [ Ctrl + s]</v-btn
          >
        </p>
      </v-card-text>
    </v-card>
    <v-card :loading="loading">
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                @input="validate"
                :rules="totalRules"
                autofocus
                outlined
                dense
                v-model="paiement.total"
                type="number"
                prefix="$"
                label="Montant total"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-select
                @input="validate"
                :rules="descriptionRules"
                dense
                outlined
                v-model="paiement.description"
                :items="[
                  'cash',
                  'credit-manuel',
                  'debit',
                  'cheque',
                  'e-transfer',
                  'mixte',
                  'ajustement',
                ]"
                label="Méthode"
              ></v-select>
            </v-col>

            <v-col cols="12" md="3" v-if="paiement.description == 'ajustement'">
              <v-text-field
                outlined
                dense
                v-model="paiement.desc_ajustement"
                type="text"
                label="Description ajustement"
                required
                hint="Explication pour le client"
                maxlength="255"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                outlined
                dense
                clearable
                v-model="paiement.timestamp"
                type="date"
                max="2999-01-01"
                label="Date paiement"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-textarea
                label="Notes pour pharmacie seulement"
                auto-grow
                outlined
                v-model="paiement.notes"
              ></v-textarea>
            </v-col>

            <v-col cols="12" md="6" v-if="paiement.description == 'ajustement'">
              <v-alert dense outlined type="info">
                Exemple Curateur Publique:<br />
                Dans dossier patient: Montant positif avec description
                "Refacturation curateur publique: Culottes TENA"<br />
                Dans dossier du curateur: Faire un ajustement <b>négatif</b> avec
                description "Refacturation curateur publique: Culottes TENA"
              </v-alert>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import $axios from "../../plugins/axios";

export default {
  data() {
    return {
      valid: true,
      loading: false,
      patient: {},
      paiement: {},
      titre: "Nouveau paiement",
      totalRules: ["Montant requis!"],
      descriptionRules: ["Méthode requis!"],
    };
  },
  computed: {
    ...mapGetters(["db", "user"]),
  },
  methods: {
    async updatePaiement() {
      try {
        if ( this.$refs.form.validate() ){
          this.loading = true;
          await $axios.post(
            process.env.VUE_APP_API_URL + "/facturation/insert_update_paiement/",
            Object.assign(this.paiement, {
              updated_by: this.user.id,
              patient_id: this.$route.params.patient_id,
            })
          );
          this.$router.push("/facturation/" + this.$route.params.patient_id);
        }
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },
    async getPaiement(paiement_id) {
      try {
        this.loading = true;
        let data = await $axios.get(
          process.env.VUE_APP_API_URL + `/paiement?paiement_id=${paiement_id}`
        );
        if (data.data !== "undefined") {
          data.data.timestamp = data.data.timestamp.split(" ")[0];
          this.paiement = data.data;
          this.validate();
        }
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },
    goToListPage() {
      this.$router.push(`/facturation/${this.$route.params.patient_id}`);
    },
    validate() {
      if (typeof this.paiement.description == undefined  || this.paiement.description == null || this.paiement.description==""){
        this.descriptionRules = ["Méthode requis!"];
      }else{
        this.descriptionRules = [true];
      }
      if (this.paiement.total == null  || this.paiement.total==""){
        this.totalRules = ["Montant invalide"];
      }else{
        this.totalRules = [true];
      }
    },
  },
  mounted: function () {
    this.patient = this.db.patient[this.$route.params.patient_id];
    if (typeof this.$route.params.paiement_id != "undefined") {
      //this.paiement = this.db.paiement[this.$route.params.paiement_id];
      this.titre = "Edition paiement";
      this.getPaiement(this.$route.params.paiement_id);
    } else {
      this.titre = "Nouveau paiement";
      this.paiement.timestamp = this.paiement.timestamp
        ? this.paiement.timestamp?.split("T")[0]
        : new Date().toLocaleDateString("en-CA");
    }
  },
};
</script>
