
<template>
  <v-container fluid>
    <form @submit.prevent="onSubmit" enctype="multipart/form-data">
      <v-file-input
        type="file"
        v-model="file"
        accept="image/jpeg,image/jpg,application/pdf"
        @change="onFileUploadChange"
      ></v-file-input>
      <v-btn color="primary" class="mx-1 mb-2" small dense type="submit"
        >Envoyer</v-btn
      >
      <h5>{{ message }}</h5>
    </form>
    <v-row dense :loading="loading">
      <v-col
        v-for="piece in fichiersNonAssociesEtAssocies"
        :key="piece.id"
        :cols="12"
      >
        <v-hover>
          <v-card
            :loading="loadingPieceIds.includes(piece.id)"
            v-bind:class="{ 'orange-border': piece.patient_id == null }"
            v-if="piece.type == 'pdf'"
          >
            <iframe
              :src="piece.filename"
              width="100%"
              height="200px"
              frameborder="0"
            >
            </iframe>
            <v-card-actions class="pa-0">
              <center>
                <v-btn
                  class="ma-1"
                  fab
                  dark
                  x-small
                  color="primary"
                  v-on:click="ouvrir(piece)"
                  ><v-icon dark>mdi-magnify</v-icon></v-btn
                >
                <v-btn
                  class="ma-1"
                  fab
                  dark
                  x-small
                  color="primary"
                  v-on:click="effacerPieceJointe(piece)"
                  ><v-icon dark>mdi-delete</v-icon></v-btn
                >
                <v-btn
                  class="ma-1"
                  dark
                  x-small
                  color="primary"
                  v-on:click="attacher(piece.id)"
                  v-if="piece.patient_id == null"
                  >Lier</v-btn
                >
              </center>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import $axios from "../plugins/axios";

export default {
  props: ["patient_id"],
  data() {
    return {
      overlay: false,
      loadingPieceIds: [],
      loading: false,
      fichiers: [],
      file: null,
      message: "",
    };
  },

  computed: {
    fichiersNonAssociesEtAssocies: function () {
      return Object.values(this.db.fichier)
        .concat(this.fichiers)
        .map((el) => {
          console.log("computed");
          return {
            ...el,
            filename:
              process.env.VUE_APP_API_URL +
              "/storage/piece_jointe/" +
              el.filename,
            uploaded_at: el.uploaded_at.substring(0, 10),
            non_associe: !Object.prototype.hasOwnProperty.call(
              el,
              "patient_id"
            ),
          };
        });
    },
    ...mapGetters(["db", "user", "decoded"]),
  },
  created: function () {
    this.loadFichiers(this.patient_id);
  },
  watch: {
    patient_id: function (newVal) {
      this.loadFichiers(newVal);
    },
  },
  methods: {
    async attacher(id) {
      try {
        this.loadingPieceIds.push(id);
        await $axios.post(process.env.VUE_APP_API_URL + "/fichier/attacher/", {
          fichier_id: id,
          patient_id: this.patient_id,
        });
      } catch (error) {
        alert(error);
      } finally {
        if (this.loadingPieceIds.indexOf(id) > -1) {
          this.loadingPieceIds.splice(this.loadingPieceIds.indexOf(id), 1);
        }
        this.loadFichiers();
      }
    },
    async loadFichiers(patient_id) {
      if (typeof patient_id != "undefined") {
        try {
          this.loading = true;
          let resp = await $axios.get(
            process.env.VUE_APP_API_URL + `/fichier?patient_id=` + patient_id
          );
          this.fichiers = resp.data;
          this.loading = false;
        } catch (error) {
          alert(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async ouvrir(pieceJointe) {
      window.open(pieceJointe.filename);
    },
    async effacerPieceJointe(piece_jointe) {
      try {
        if (confirm("Détruire pièce jointe?") == true) {
          await $axios.get(
            process.env.VUE_APP_API_URL +
              `/fichier/effacer?id=${piece_jointe.id}&user_id=${this.user.id}`
          );
        }
      } catch (error) {
        alert(error);
      } finally {
        this.loadFichiers(this.patient_id);
      }
    },
    onFileUploadChange() {
      const allowedTypes = ["image/jpeg", "image/jpg", "application/pdf"];
      if (!allowedTypes.includes(this.file.type)) {
        this.file = null
        this.message = "Format de fichier invalide!!";
        return false
      }
      if (this.file.size > 10000000) {
        this.file = null
        this.message = "Trop volumineux, la taille maximale autorisée est de 10Mo";
        return false
      }
    },
    async onSubmit() {
      if (this.file != null) {
        const formData = new FormData();
        formData.append("file", this.file);
        this.file = null;
        try {
          $axios
            .post(
              process.env.VUE_APP_API_URL +
                "/upload/" +
                this.decoded.succursale.id +
                "/" +
                this.patient_id,
              formData
            )
            .then((res) => {
              this.message = res.data.message;
              this.loadFichiers(this.patient_id);
            });
        } catch (err) {
          console.log(err);
        }
      }
    },
  },
};
</script>

<style scoped>
.orange-border {
  border: 10px solid orange;
}
</style>