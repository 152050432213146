<template>
    <p> {{ data }} </p>
</template>

<script>
import $axios from "../plugins/axios";
export default {
  data() {
    return {
      data : ''
    };
  },
  methods: {
    async getBackendStatus() {
        try{
            let resp = await $axios.get(process.env.VUE_APP_API_URL + `/check-status`);
            this.data = resp.data.status
        }catch(error){
            this.data = false
        }
    },
  },
  beforeMount() {
    this.getBackendStatus()
  }
};
</script>

<style>
</style>