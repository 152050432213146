var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-card',{staticClass:"mx-auto my-4",attrs:{"loading":_vm.loading}},[_c('v-card-text',[_c('p',{staticClass:"text-h4 text--primary"},[_vm._v(" "+_vm._s(_vm.titre)+": "+_vm._s(_vm.patient.prenom)+" "+_vm._s(_vm.patient.nom)+" "),_c('v-btn',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['ctrl', 'z']),expression:"['ctrl', 'z']"}],staticClass:"mr-4",attrs:{"color":"error"},on:{"shortkey":function($event){return _vm.goToListPage()},"click":_vm.goToListPage}},[_vm._v("Annuler [ Ctrl + z]")]),_c('v-btn',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['ctrl', 's']),expression:"['ctrl', 's']"}],staticClass:"mr-4",attrs:{"color":"success","disabled":!_vm.valid},on:{"shortkey":function($event){return _vm.updatePaiement()},"click":_vm.updatePaiement}},[_vm._v("Sauvegarder [ Ctrl + s]")])],1)])],1),_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"rules":_vm.totalRules,"autofocus":"","outlined":"","dense":"","type":"number","prefix":"$","label":"Montant total"},on:{"input":_vm.validate},model:{value:(_vm.paiement.total),callback:function ($$v) {_vm.$set(_vm.paiement, "total", $$v)},expression:"paiement.total"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"rules":_vm.descriptionRules,"dense":"","outlined":"","items":[
                'cash',
                'credit-manuel',
                'debit',
                'cheque',
                'e-transfer',
                'mixte',
                'ajustement',
              ],"label":"Méthode"},on:{"input":_vm.validate},model:{value:(_vm.paiement.description),callback:function ($$v) {_vm.$set(_vm.paiement, "description", $$v)},expression:"paiement.description"}})],1),(_vm.paiement.description == 'ajustement')?_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"text","label":"Description ajustement","required":"","hint":"Explication pour le client","maxlength":"255"},model:{value:(_vm.paiement.desc_ajustement),callback:function ($$v) {_vm.$set(_vm.paiement, "desc_ajustement", $$v)},expression:"paiement.desc_ajustement"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","clearable":"","type":"date","max":"2999-01-01","label":"Date paiement"},model:{value:(_vm.paiement.timestamp),callback:function ($$v) {_vm.$set(_vm.paiement, "timestamp", $$v)},expression:"paiement.timestamp"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-textarea',{attrs:{"label":"Notes pour pharmacie seulement","auto-grow":"","outlined":""},model:{value:(_vm.paiement.notes),callback:function ($$v) {_vm.$set(_vm.paiement, "notes", $$v)},expression:"paiement.notes"}})],1),(_vm.paiement.description == 'ajustement')?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-alert',{attrs:{"dense":"","outlined":"","type":"info"}},[_vm._v(" Exemple Curateur Publique:"),_c('br'),_vm._v(" Dans dossier patient: Montant positif avec description \"Refacturation curateur publique: Culottes TENA\""),_c('br'),_vm._v(" Dans dossier du curateur: Faire un ajustement "),_c('b',[_vm._v("négatif")]),_vm._v(" avec description \"Refacturation curateur publique: Culottes TENA\" ")])],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }