<template>
  <v-container>
    <v-card class="mx-auto my-4" :loading="loading">
      <v-card-text>
        <p class="text-h4 text--primary">
          Modifier patient: {{ patient.prenom }} {{ patient.nom }}
          <v-btn
            color="error"
            v-shortkey="['ctrl', 'z']"
            @shortkey="goToFacturationPage()"
            @click="goToFacturationPage()"
            class="mr-4"
            >Annuler [ Ctrl + z]</v-btn
          >
          <v-btn
            color="success"
            v-shortkey="['ctrl', 's']"
            @shortkey="updatePatient()"
            @click="updatePatient"
            class="mr-4"
            :disabled="!valid"
            >Sauvegarder [ Ctrl + s]</v-btn
          >
        </p>
      </v-card-text>
    </v-card>
    <v-card :loading="loading">
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <p v-if="patient.date_fermeture_compte">
            <v-alert type="error" dense
              >Compte fermé le {{ patient.date_fermeture_compte }}</v-alert
            >
          </p>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                @input="validate"
                :rules="dateOuvertureRules"
                outlined
                dense
                clearable
                v-model="patient.date_ouverture_compte"
                type="date"
                max="2999-01-01"
                label="Date ouverture compte"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                @input="validate"
                :rules="soldeOuvertureRules"
                outlined
                dense
                v-model="patient.solde_ouverture_compte"
                type="number"
                prefix="$"
                label="Solde ouverture compte"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                outlined
                dense
                clearable
                v-model="patient.date_fermeture_compte"
                type="date"
                max="2999-01-01"
                label="Date fermeture compte"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                dense
                v-model="patient.cc"
                type="number"
                counter="16"
                label="Carte de crédit"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                dense
                v-model="patient.cc_exp"
                type="number"
                counter="4"
                label="Carte de crédit expiration"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                dense
                v-model="patient.cc_cvv"
                type="number"
                counter="3"
                label="Carte de crédit CVV"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-checkbox
                dense
                outlined
                v-model="patient.ppa"
                :label="`Paiement pré-autorisé (PPA)`"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                dense
                v-model="patient.ppa_autorisation"
                type="text"
                label="PPA autorisation notes"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                dense
                v-model="patient.facture_par_courriel_adresse"
                type="text"
                label="Courriel pour facturation"
                hint='Si plusieurs courriels, inscrire au format "courriel1@gmail.com,courriel2@gmail.com"'
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                dense
                outlined
                required
                :rules="facturePapierRules"
                v-model="patient.facture_methode"
                :items="[
                  'viarx',
                  'poster_chez_patient',
                  'poster_autre_adresse',
                  'courriel',
                  'non',
                  'fax'
                ]"
                label="Facture papier"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
              v-if="patient.facture_methode == 'poster_autre_adresse' || patient.facture_methode == 'fax' "
            >
              <v-textarea
                label="Adresse pour poster facture"
                auto-grow
                outlined
                v-model="patient.facture_autre_adresse_ou_fax"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                dense
                v-model="patient.password_einterac"
                type="text"
                label="eInterac et autres mots-clés"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-textarea
                label="Notes"
                auto-grow
                outlined
                v-model="patient.notes"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import $axios from "../plugins/axios";

export default {
  data() {
    return {
      valid: true,
      loading: false,
      patient: {},
      dateOuvertureRules: [true],
      soldeOuvertureRules: [true],
      facturePapierRules: [(v) => !!v || "Méthode facture papier requis!"]
    };
  },
  computed: {
    ...mapGetters(["db", "user", "getPatientById"]),
    compte_ferme: function () {
      return this.patient.date_fermeture_compte ? true : false;
    },
  },
  methods: {
    async updatePatient() {
      try {
        if ( this.$refs.form.validate() ){
            this.loading = true;

            var formData = Object.assign(this.patient, { updated_by: this.user.id,date_fermeture_compte : this.patient.date_fermeture_compte!='' ? this.patient.date_fermeture_compte : null,date_ouverture_compte : this.patient.date_ouverture_compte!='' ? this.patient.date_ouverture_compte : null })
            delete formData.fullname;
            delete formData.searchTerm;
            let resp = await $axios.post(
              process.env.VUE_APP_API_URL + "/facturation/update_patient/",
              formData
            );
            if(typeof resp.data.error !=undefined && resp.data.error){
              alert(resp.data.msg);
            }else{
              this.$router.push("/facturation/" + this.$route.params.patient_id);
            }
            

          }
        } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },
    goToFacturationPage: function () {
      this.$router.push("/facturation/" + this.$route.params.patient_id);
    },
    validate() {
      if (
        this.patient.date_ouverture_compte != null &&
        this.patient.date_ouverture_compte != ""
      ) {
        var date = this.$date(this.patient.date_ouverture_compte);
        if (date.isValid()) {
        this.soldeOuvertureRules =
            this.patient.solde_ouverture_compte == null ||
            this.patient.solde_ouverture_compte == ""
              ? ["Solde invalide!"]
              : [true];
        } else {
          this.dateOuvertureRules = ["Date invalide!"];
      }
      } else {
        this.soldeOuvertureRules = [true];
        this.dateOuvertureRules = [true];
      }
    },
  },

  mounted: function () {
    this.patient = Object.assign(
      {},
      this.patient,
      this.db.patient[this.$route.params.patient_id]
    );
    this.patient.date_ouverture_compte = this.toDate(this.patient.date_ouverture_compte)
    this.patient.date_fermeture_compte = this.toDate(this.patient.date_fermeture_compte)
    delete this.patient.ddn;
  },
};
</script>
