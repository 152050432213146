import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Tests from '../views/Tests.vue'
import Login from '../views/Login.vue'
import Facturation from '../views/Facturation.vue'
import CatalogueMcKesson from '../views/McKesson/Catalogue.vue'
import ProduitMcKesson from '../views/McKesson/Produit.vue'
import FacturationDetail from '../views/FacturationDetail.vue'
import PatientModifier from '../views/PatientModifier.vue'
import PaiementUpdate from '../views/Facturation/PaiementUpdate.vue'
import Fichier from '../views/Fichier.vue'
import RechercheProduit from '../views/RechercheProduit.vue'
import RapportProfit from '../views/Rapports/Profit'
import BatchRenewal from '../views/Batch/BatchRenewal'
import AppStatus from '../views/AppStatus'
import ListePatient from '../views/ListePatient.vue'
import Imprimante from '../views/Imprimante.vue'
import RegistreChangement from '../views/RegistreChangement.vue'
import SendMail from '../views/Mailing/SendMail'
import RapportClover from '../views/Rapports/Clover'
import VerificationRenouvellement from '../views/VerificationRenouvellement.vue'
import store from "../store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/status',
    name: 'AppStatus',
    component: AppStatus
  },
  {
    path: '/',
    name: 'Main',
    component: Main,
    meta: {
      requiresAuth: true,
      requiresSync: true
    },
    children: [
      {
        path: '/tests',
        name: 'Tests',
        component: Tests
      },
      {
        path: '/imprimante',
        name: 'imprimante',
        component: Imprimante
      },
      {
        path: '/facturation',
        name: 'facturation',
        component: Facturation
      },
      {
        path: '/mckesson/catalogue',
        name: 'CatalogueMcKesson',
        component: CatalogueMcKesson
      },
      {
        path: '/mckesson/produit',
        name: 'ProduitMcKesson',
        component: ProduitMcKesson
      },
      {
        path: '/facturation/:patient_id',
        component: FacturationDetail
      },
      {
        path: '/facturation/modifier/:patient_id',
        component: PatientModifier
      },
      {
        path: '/facturation/:patient_id/paiement',
        component: PaiementUpdate
      },
      {
        path: '/facturation/:patient_id/paiement/:paiement_id',
        component: PaiementUpdate
      },
      {
        path: '/rapport/profit',
        component: RapportProfit
      },
      {
        path: '/rapport/clover',
        component: RapportClover
      },
      {
        path: '/fichier',
        component: Fichier
      },
      {
        path: '/recherche_produit',
        component: RechercheProduit
      },
      {
        path: '/lots',
        component: BatchRenewal
      },
      {
        path: '/liste_patient',
        component: ListePatient
      },
      {
        path: '/registre/:patient_id',
        component: RegistreChangement
      },
      {
        path: '/sendmail/:patient_id',
        component: SendMail
      },
      {
        path: '/renouvellement/verification',
        component: VerificationRenouvellement
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// AUTH
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

// SYNC
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresSync)) {
    if (!store.state.sync.firstSyncCompleted) {
      await store.dispatch('firstSync')
      store.dispatch('hideLoader')
    }
    next()
  } else {
    next()
  }
})

export default router
