import Vue from 'vue'
import $axios from '../../plugins/axios'

export default {
    state: {
        db: {
            // DÉFINIR TOUTES LES COLLECTIONS à SYNCHRONISER
            succursales: {},
            employe: {},
            patient: {},
            fichier: {},
            foyer: {}
        },
        isSyncing: false,
        syncToken: {},
        firstSyncCompleted: false,
    },
    getters: {
        db: (state) => state.db,
        succusalesArray: state => Object.values(state.db.succursales),
        patientsArray: (state) => Object.values(state.db.patient)
            .map(o => ({ ...o, ddn: o.ddn?.split('T')[0] }))
            .sort((a) => (a.date_ouverture_compte == null) ? 1 : -1),
        getPatientById: (state) => (id) => {
            return state.db.patient[id]
        },
        employeArray: state => Object.values(state.db.employe),
        syncToken: state => new URLSearchParams(state.syncToken).toString(),
    },
    actions: {
        firstSync({ commit, state, dispatch }) {
            console.log('FirstSync')
            return new Promise((resolve) => {
                Object.keys(state.db).forEach( coll => {
                    commit('setSyncToken', [coll, -1])
                })
                dispatch('sync').then( () => {
                    var source = new EventSource(process.env.VUE_APP_API_URL + "/sse");
                    source.onmessage = function(e) {
                        console.log(e.data)
                        if (e.data == "update"){
                            dispatch('sync');
                        }
                    };
                    resolve()
                    commit('completeFirstSync');
                }).catch( e => {
                    alert('Erreur avec la synchronisation des données: ' + e)
                    console.log(e)
                })
            })
        },
        sync({ commit, getters }) {
            return new Promise((resolve, reject) => {
                commit('startSync')
                $axios.get('/sync?' + getters.syncToken)
                    .then(resp => {
                        commit('sync', resp.data.sync)
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally( () => commit('endSync'))
            })
        },
    },
    mutations: {
        setSyncToken (state, payload) {
            Vue.set(state.syncToken, payload[0], payload[1])
        },
        startSync (state) {
            state.isSyncing = true;
        },
        endSync (state) {
            state.isSyncing = false;
        },
        reinitializeSyncState (state){
            state.firstSyncCompleted = false;
        },
        completeFirstSync (state) {
            // Vue.set(state.syncToken, payload[0], payload[1])
            state.firstSyncCompleted = true;
        },
        sync(state, payload) {
            console.log(payload)
            Object.keys(payload).forEach(coll => {
                state.syncToken[coll] = payload[coll]['timestamp'];

                payload[coll]['delete'].forEach( id => {
                    Vue.delete(state.db[coll], id)
                })  

                payload[coll]['insertOrUpdate'].forEach( row => {
                    if(coll=='patient'){
                        let normalizeNom = row['nom'].normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                        let normalizePrenom = row['prenom'].normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                        let searchTerm = [row['nom'],row['prenom'],row['ddn'],row['appt'],row['password_einterac'],normalizeNom!=row['nom'] ? normalizeNom : undefined,normalizePrenom!=row['prenom'] ? normalizePrenom : undefined]
                        row['searchTerm'] = searchTerm.join(" ").toLowerCase()
                    }
                    Vue.set(state.db[coll], row[payload[coll]['id']], row)
                })

            })
        },
    },

  }