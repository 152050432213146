<template>
    <v-container fluid>
        <v-card class="mx-auto">
            <v-skeleton-loader v-if="loading" type="table-tbody"></v-skeleton-loader>
            <template v-else>
                <template v-if="fullRegistre != null">
                    <div v-for="(categorie, pilulier) in fullRegistre" :key="pilulier">
                        <h4>{{ pilulier }} : {{ Object.keys(categorie).length }} Rx</h4>
                        <table class="table-registre">
                            <thead>
                                <tr class="header">
                                    <th>#/7jr</th>
                                    <th class="text-left">Médicament</th>
                                    <th class="text-left">Teneur</th>
                                    <th>Presc.</th>
                                    <td v-for="(h, horaireName) in abbrvHoraire" :key="horaireName">{{ h }}</td>
                                </tr>
                            </thead>
                            <tbody v-for="(item, index) in categorie" :key="item.nom + index">
                                <tr class="bold-line">
                                    <td :rowSpan="item.note_distribution != '' ? 2 : 1"
                                        :class="'qte-duree-column ' + getClass(`${pilulier}.${index}`, 'qte_duree')">{{
                                        item.qte_duree }}</td>
                                    <td :rowSpan="item.note_distribution != '' ? 2 : 1"
                                        :class="'medicament-column ' + getClass(`${pilulier}.${index}`, 'nom')">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on"> {{ item.nom }} </span>
                                            </template>
                                            <span>Rx : {{ item.no_rx }}</span>
                                        </v-tooltip>
                                    </td>
                                    <td :rowSpan="item.note_distribution != '' ? 2 : 1"
                                        :class="'teneur-column ' + getClass(`${pilulier}.${index}`, 'teneur')">{{
                                        item.teneur }}</td>
                                    <td :rowSpan="item.note_distribution != '' ? 2 : 1"
                                        :class="'prescripteur-column ' + getClass(`${pilulier}.${index}`, 'prescripteur_nom_code')">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on"> {{ item.prescripteur_nom_code }} </span>
                                            </template>
                                            <span>{{ item.prescripteur_nom_complet }}</span>
                                        </v-tooltip>
                                    </td>
                                    <td :class="'horaire-column ' + getClass(`${pilulier}.${index}`, `posologie.${horaireName}`)"
                                        v-for="(seq, horaireName) in item.posologie" :key="horaireName">{{ seq }}</td>
                                </tr>
                                <tr v-if="item.note_distribution != ''">
                                    <td colspan="4" :class="getClass(`${pilulier}.${index}`, 'note_distribution')">{{
                                        item.note_distribution }}</td>
                                </tr>
                                <tr>
                                    <td colspan="8">
                                        <span v-if="item.posologie_type != 'quotidienne' && item.posologie_text != ''"
                                            :class="getClass(`${pilulier}.${index}`, 'posologie_text')"> {{
                                            item.posologie_text }} </span>
                                        <v-chip v-if="item.periode != ''" color="yellow" x-small> {{ item.periode
                                            }}</v-chip>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <tbody>
                            <tr v-for="(item, index) in categorie" :key="item.nom + index"
                                :set="changeClass=getChange(`${pilulier}.${index}`)">
                                <td v-if="changeClass!=''">
                                    <v-chip
                                        :class="changeClass=='deleted' ? 'red' : (changeClass=='updated' ? 'orange' : 'green')"
                                        x-small> {{ changeClass=='deleted' ? 'SUPPRIMÉ' : (changeClass=='updated' ?
                                        'MODIFIÉ' : 'AJOUTÉ')}} </v-chip>
                                    <span> {{ item.nom }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </div>
                </template>
                <v-alert v-else type="warning">La version {{ version }} est vide</v-alert>

                <v-card-actions v-if="fullRegistre != null">
                    <v-row no-gutters>
                        <v-dialog v-model="dialog" persistent max-width="400"
                            v-if="registre_changement?.registre && type == 'droite' && user.type == 'pharmacien' && user.licence != ''">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="mx-1" small color="primary" dark v-bind="attrs" v-on="on" dense>
                                    Signer registre
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="text-h5">
                                    Confirmation
                                </v-card-title>
                                <v-card-text>Voulez-vous signer le registre de médicaments?</v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="error" @click="dialog = false">
                                        Non
                                    </v-btn>
                                    <v-btn color="success" v-on:click="signerRegistre()">
                                        Oui
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-row>
                </v-card-actions>
            </template>
        </v-card>
    </v-container>
</template>
<script>
import { getProperty, hasProperty } from "dot-prop";
import { mapGetters } from "vuex";

export default {
    props: ["patient_id", "registre_changement", "type", "show_diff", "loading", "version"],
    data() {
        return {
            expanded: [],
            headers: [
                { text: 'Qte/Durée', value: 'qte_duree', sortable: false },
                { text: 'Nom', value: 'nom' },
                { text: 'Teneur', value: 'teneur' },
                { text: 'Debut', value: 'debut' },
                { text: 'Fin', value: 'fin' },
                { text: 'Prescripteur', value: 'prescripteur' },
                { text: '', value: 'data-table-expand' },
            ],
            abbrvHoraire: ['AM', 'DIN', 'SOU', 'HS'],
            horaires: ['MP1', 'MP2', 'MP3', 'MP4'],
            dialog: false,
            fullRegistre: null,
            diff: {
                updated: null,
                deleted: null,
                added: null,
            }
        };
    },
    computed: {
        ...mapGetters(['user']),
    },
    watch: {
        registre_changement: function () {
            this.fullRegistre = JSON.parse(JSON.stringify(this.registre_changement?.registre));
            const categorie = Object.keys(this.fullRegistre);
            if (categorie.length > 0) {
                categorie.forEach(categorie => {
                    const ordonnances = Object.keys(this.fullRegistre[categorie]);
                    ordonnances.forEach(ord => {
                        this.fullRegistre[categorie][ord] = this.formatRegistre(this.fullRegistre[categorie][ord])
                    })
                })
            }
            ['added', 'updated', 'deleted'].forEach(grp => {
                if (this.registre_changement?.diff?.[grp]) {
                    this.diff[grp] = JSON.parse(JSON.stringify(this.registre_changement?.diff?.[grp]));
                    const groups = Object.keys(this.diff[grp]);
                    if (groups.length > 0) {
                        groups.forEach(categorie => {
                            const products = Object.keys(this.diff[grp][categorie]);
                            products.forEach(productId => {
                                if (grp != 'deleted') {
                                    this.diff[grp][categorie][productId] = this.formatDiff(this.diff[grp][categorie][productId], `${categorie}.${productId}`)
                                } else {
                                    const attributes = Object.keys(this.diff[grp][categorie][productId]);
                                    if (attributes.length == 14) {
                                        this.fullRegistre[categorie][productId] = this.formatRegistre(this.diff[grp][categorie][productId])
                                    } else {
                                        this.diff[grp][categorie][productId] = this.formatDiff(this.diff[grp][categorie][productId], `${categorie}.${productId}`)
                                    }

                                }
                            })
                        })
                    }
                } else {
                    this.diff[grp] = null
                }
            })

        }
    },
    methods: {
        printRegistre(){
            const paramsObj = { patient_id: this.patient_id, registre_changement: this.registre_changement };
            this.$router.push({ name: 'FeuilleRegistre', params: paramsObj });
        },
        formatDecimal(val) {
            return typeof val != 'undefined' ? val % 1 === 0 ? val.toFixed() : val.toFixed(2).replace(/\.00$/, '') : ''
        },
        formatRegistre(item) {
            return {
                qte_duree: this.formatDecimal(item.qte_duree),
                nom: item.nom.trim(),
                no_rx: item.no_rx,
                teneur: item.teneur,
                prescripteur_nom_complet: `${item.prescripteur_nom} ${item.prescripteur_licence}`,
                prescripteur_nom_code: this.formatPrescripteur(item.prescripteur_nom),
                note_distribution: item.note_distribution,
                periode: `${(item.debut_datetime_swt != null && item.debut_datetime_swt != '' && item.debut_datetime_swt != '1753-01-01') ? 'Début: ' : ''} ${this.toDate(item.debut_datetime_swt, '-')} ${(item.fin_datetime_swt != null && item.fin_datetime_swt != '') ? 'Fin: ' : ''} ${this.toDate(item.fin_datetime_swt, '-')}`.trim(),
                posologie_text: `${item.posologie_text} ${this.formatFrequence(item.posologie_type, item.posologie_json)}`.trim(),
                posologie: this.formatPosologie(item.posologie_type, item.posologie_json),
                posologie_type: item.posologie_type,
                posologie_json: item.posologie_json
            };
        },
        formatDiff(item, path) {
            const currentItem = getProperty(this.registre_changement?.registre, path)
            const finalObject = {};
            if (item.qte_duree) {
                finalObject.qte_duree = this.formatDecimal(item.qte_duree);
            }
            if (item.nom) {
                finalObject.nom = (item.nom).trim();
            }
            if (item.teneur) {
                finalObject.teneur = item.teneur;
            }
            if ((item.prescripteur_nom) || (item.prescripteur_licence)) {
                finalObject.prescripteur_nom_complet = `${item.prescripteur_nom ?? currentItem.prescripteur_nom} ${item.prescripteur_licence ?? currentItem.prescripteur_licence}`.trim();
            }

            if (item.prescripteur_nom) {
                finalObject.prescripteur_nom_code = this.formatPrescripteur(item.prescripteur_nom);
            }

            if (item.note_distribution) {
                finalObject.note_distribution = item.note_distribution;
            }

            if ((item.debut_datetime_swt) || (item.fin_datetime_swt)) {
                finalObject.periode = `${(item.debut_datetime_swt != null && item.debut_datetime_swt != '' && item.debut_datetime_swt != '1753-01-01') ? 'Début: ' : ''} ${this.toDate(item.debut_datetime_swt ?? currentItem.debut_datetime_swt, '-')} ${(item.fin_datetime_swt != null && item.fin_datetime_swt != '') ? 'Fin: ' : ''} ${this.toDate(item.fin_datetime_swt ?? currentItem.fin_datetime_swt, '-')}`.trim();
            }

            if ((item.posologie_text)) {
                finalObject.posologie_text = `${item.posologie_text} ${this.formatFrequence(currentItem.posologie_type, currentItem.posologie_json)}`.trim();
            }

            if (item.posologie_json) {
                finalObject.posologie = this.formatPosologie(item.posologie_type ?? currentItem.posologie_type, item.posologie_json);

            }
            if (item.posologie_type) {
                finalObject.posologie_type = item.posologie_type;
            }
            return finalObject;
        },
        formatPosologie(type, posologie_json) {
            if (type == 'aucune') return null;
            if (type == 'quotidienne') return Object.fromEntries(
                Object.entries(posologie_json.sequence).slice(0, 4)
            );
            if (type == 'hebdomadaire') {
                return this.formatPosologieHebdo(posologie_json);
            }
            if (type == 'séquentielle') {
                return this.formatPosologieSequentielle(posologie_json);
            }
        },
        formatPrescripteur(nom) {
            if (typeof nom != 'undefined') {
                let strs_nom = nom.split(' ')
                return (this.capitalize(strs_nom[0].substr(0, 3)) + (strs_nom.length > 1 ? this.capitalize(strs_nom[strs_nom.length - 1].substr(0, 2)) : ''))
            }
            return ''
        },
        formatFrequence(posologie_type, posologie_json) {
            if (posologie_json != null && posologie_json.frequence > 1) {
                if (posologie_type == 'hebdomadaire') {
                    return ` chaque ${posologie_json.frequence} semaines`;
                } else if (posologie_type == 'séquentielle') {
                    return ` tous les ${posologie_json.frequence} jours`;
                }
            }
            return '';
        },
        formatPosologieHebdo(posologie_json) {
            const daysOfWeek = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche']
            var horairePrise = {}
            if (typeof posologie_json.sequence != "undefined") {
                daysOfWeek.forEach(day => {
                    this.horaires.forEach((horaire) => {
                        if (typeof posologie_json.sequence[day] != "undefined" && typeof posologie_json.sequence[day].sequence != "undefined" && typeof posologie_json.sequence[day].sequence[horaire] != "undefined") {
                            if (typeof horairePrise[horaire] == "undefined") {
                                horairePrise[horaire] = ''
                            }
                            if (posologie_json.sequence[day].sequence[horaire] != "") {
                                horairePrise[horaire] = 'X'
                            }

                        }
                    });
                });
            }
            return horairePrise

        },
        formatPosologieSequentielle(posologie_json) {
            var horairePrise = {}
            if (typeof posologie_json.sequence != "undefined") {
                Object.keys(posologie_json.sequence).forEach(seq => {
                    this.horaires.forEach((horaire) => {
                        if (typeof posologie_json.sequence[seq] != "undefined" && typeof posologie_json.sequence[seq][horaire] != "undefined") {
                            if (typeof horairePrise[horaire] == "undefined") {
                                horairePrise[horaire] = ''
                            }
                            if (posologie_json.sequence[seq][horaire] != "") {
                                horairePrise[horaire] = 'X'
                            }

                        }
                    });
                });
            }
            return horairePrise

        },
        getClass(main_path, property) {
            if(this.show_diff){
                if (hasProperty(this.diff.deleted, main_path)) {
                    const deletedItem = getProperty(this.diff.deleted, main_path);
                    if (Object.keys(deletedItem).length == 14 || hasProperty(this.diff.deleted, `${main_path}.${property}`)) {
                        return 'deleted-item';
                    }
                }
                let is_updated = hasProperty(this.diff.updated, `${main_path}.${property}`);
                let is_added = hasProperty(this.diff.added, `${main_path}.${property}`);
                if (is_added) {
                    return 'added-item';
                } else if (is_updated) {
                    return 'updated-item';
                }
            }
            
            return '';
        },
        getChange(main_path) {
            if(this.show_diff){
                if (hasProperty(this.registre_changement?.diff?.deleted, main_path)) {
                    const deletedItem = getProperty(this.registre_changement?.diff.deleted, main_path);
                    if (Object.keys(deletedItem).length == 14) {
                        return 'deleted';
                    }
                }
                if (hasProperty(this.registre_changement?.diff?.added, main_path)) {
                    const addedItem = getProperty(this.registre_changement?.diff.added, main_path);
                    if (Object.keys(addedItem).length == 14) {
                        return 'added';
                    }
                }
                if ( hasProperty(this.registre_changement?.diff?.updated, main_path)) {
                    const upatedItem = getProperty(this.registre_changement?.diff.updated, main_path);
                    if (Object.keys(upatedItem).length > 0) {
                        return 'updated';
                    }
                }
            }
            return '';
        },
        toDate(value, separator) {
            if (!value) return '';
            const date = new Date(value);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}${separator}${month}${separator}${day}`;
        },
        capitalize(word) {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        },
    },
};
</script>

<style scoped>
.v-card {
    padding: 15px;
}

.qte-duree-column {
    width: 35px;
    text-align: right;
    padding: 0px 2px;
}

.medicament-column {
    max-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding: 0px 2px;
}

.teneur-column {
    width: 100px;
    text-align: left;
    padding: 0px 2px;
}

.prescripteur-column {
    width: 43px;
}

.horaire-column {
    width: 30px;
}

.bold-line {
    border-bottom: 1px solid black;
}

.date-line {
    text-align: center;
}
</style>