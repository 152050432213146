<template>
  <v-app>
    <v-overlay :value="this.appLoading">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    <v-app-bar app color="white" clipped-right clipped-left>
      <v-responsive max-width="260">
        <v-form ref="form" @submit.prevent="loadPatient">
          <span v-shortkey="['ctrl', 'k']" @shortkey="focusRecherche()"></span>
          <autocomplete-patient
            :patientsArray="patientsArray"
            :searchHistory="searchHistory"
            :label="'Recherche [Ctrl+K]'"
            @patient-selected="onPatientSelected"
            ref="autocomplete"
          />
        </v-form>
      </v-responsive>

      <v-tabs centered class="ml-n9" color="grey darken-1" v-model="active_tab">
        <v-tab to="/facturation">Facturation</v-tab>
        <v-tab to="/mckesson/produit">McKesson</v-tab>
        <v-tab to="/recherche_produit">Produit</v-tab>
        <v-tab v-if="
          typeof this.$route.params.patient_id != 'undefined'
        " :to="registreLink">Registre de changements</v-tab>
        <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                class="align-self-center mr-4"
                v-bind="attrs"
                v-on="on"
              >
                Autres
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>

            <v-list class="grey lighten-3">
                            <v-list-item to="/liste_patient">
                Liste patient
              </v-list-item>
              <v-list-item to="/lots">
                Lots
              </v-list-item>
              <v-list-item to="/rapport/profit">
                Profits en erreur
              </v-list-item>
              <v-list-item to="/rapport/clover">
                Rapport des transactions Clover
              </v-list-item>
              <v-list-item to="/renouvellement/verification">
                Vérification des renouvellements
              </v-list-item>
            </v-list>
        </v-menu>
        <v-tab v-if="
          typeof this.$route.params.patient_id == 'undefined'
        "  disabled>Registre de changements</v-tab>
        <!-- <v-tab to="/rapport/profit">Profit irrégulier</v-tab> -->
      </v-tabs>
      
        <v-btn icon v-on:click="setPrinter()">
          <v-icon size="32" :class="{ 'green--text': this.isReadyToPrint, 'red--text': !this.isReadyToPrint }">mdi-printer</v-icon>
        </v-btn>
      <v-select
      class="pr-6 mt-5"
      outlined
      dense
          :items="succursales"
          item-value="id"
          item-text="id"
          :value="decoded.succursale?.id"
          @change="changeSuccursale"
        ></v-select>
      <span class="hidden-sm-and-down text-capitalize" v-if="user"
        >{{ user.fname }} {{ user.lname }}</span
      >

      <v-btn icon v-on:click="userLogout()">
        <v-icon size="32">mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-alert type="error" v-if="!isProd">Attention, ceci n'est pas la vrai version de CODEX</v-alert>
      <v-alert
        dismissible
        type="success"
        v-if="message!=null"
      >
      {{message}}
      </v-alert>
      <router-view />
    </v-main>

    <v-dialog v-model="userLoggedOut" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> NIP employé </v-card-title>
        <v-card-text>
          <v-text-field
            autofocus
            outlined
            type="number"
            label="NIP"
            v-on:input="onNipInput()"
            v-model="nip"
          ></v-text-field>
          <v-btn text color="primary" v-on:click="logout()">
            Déconnexion succursale
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import AutocompletePatient from '../components/AutocompletePatient.vue';

export default {
  components: {
    AutocompletePatient
  },
  data() {
    return {
      nip: '',
      active_tab: null,
      message: null,
      isProd: false,
      succursales: [
        {
          id: "saules",
        },
        {
          id: "oasis",
        },
        {
          id: "paton",
        }
      ]
    };
  },
  computed: {
    registreLink : function(){
      if(typeof this.$route.params.patient_id != "undefined"){
          return "/registre/"+this.$route.params.patient_id;
      }
      return ''
    },
    ...mapGetters(['decoded', 'db', 'user', 'userLoggedOut', 'patientsArray', 'searchHistory', 'isReadyToPrint', 'printerErrors',"appLoading"])
  },
  mounted() {
    let data = sessionStorage.getItem('nip');
    if (data != null) {
      this.$store.dispatch('userLogin', data).then(() => {
        this.nip = '';
      });
    }
    this.isProd = process.env.VUE_APP_APP_URL == '//codex.rxweb.ca';
    this.setPrinter();
  },
  methods: {
    userLogout(){
      this.$store.dispatch('idleInterface')
    },
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$store.commit('reinitializeSyncState');
        this.$store.commit('reinitializeSearch');
        this.$router.push('/login');
      });
    },
    focusRecherche() {
      this.$refs.autocomplete.focusRecherche();
    },
    loadPatient() {
      if(this.searchPatientId!=null){
        const currentRoute = this.$route.path;
        const newRoute = this.active_tab.startsWith('/registre/')
          ? '/registre/' + this.searchPatientId
          : '/facturation/' + this.searchPatientId;

        if (currentRoute !== newRoute) {
          this.$router.push(newRoute);
        }

        this.$refs.autocomplete.$refs.recherche.blur();
        this.searchPatientId = '';
      }
      
    },
    onNipInput() {
      if (this.nip.length >= 4) {
        this.$store.dispatch('userLogin', this.nip).then(() => {
          this.nip = '';
        });
      }
    },
    onPatientSelected(patientId) {
      if(patientId!=null){
        this.searchPatientId = patientId;
      }
    },
    async setPrinter() {
      try {
        var defaultPrinter = await this.$zebraPlugin.getDefaultPrinter();
        this.$store.dispatch('updateDefaultPrinter', defaultPrinter);
      } catch (err) {
        console.error(err);
      }
    },
    async changeSuccursale(value){
      this.$store.dispatch("showLoader");
      try {
        if(this.$route.name!='Main'){
          this.$router.push('/');
        }
        this.$store.dispatch('switchSuccursale',{
          succursale: value
        }).then(async () => {
          this.$refs.autocomplete.clearHistory();
          this.$store.commit('reinitializeSearch');
          this.$store.commit('reinitializeSyncState');
          await this.$store.dispatch('firstSync')
          this.$store.dispatch("hideLoader");
        });
      } catch (error) {
        this.$store.dispatch("hideLoader");
        alert("Erreur: " + error.response.data.msg);
      }

    }
  },
}
</script>
