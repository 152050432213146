<template>
  <div>
    <v-row no-gutters>
      <v-col cols="2">
        <v-card outlined>
          <v-card-text>
            <v-autocomplete
              v-model="foyer"
              :items="foyers"
              item-value="id"
              item-text="nom_reflex"
              no-data-text="'AUCUN RÉSULTAT"
              outlined
              solo
              dense
              hide-details
              label="Foyer"
              required
            >
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.nom_reflex }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
            <v-btn color="primary" class="mr-4 mt-2" v-on:click="loadList"
              >Générer</v-btn
            >
          </v-card-text>
        </v-card>
        <v-spacer></v-spacer>
      </v-col>

      <v-col cols="10">
        <iframe
          class="container fluid"
          :src="this.listePatientHref"
          height="800"
          frameborder="0"
        >
        </iframe>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import $axios from "../plugins/axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      foyer: null,
      foyers: [],
      listePatientHref: "",
    };
  },
  computed: {
    ...mapGetters(["decoded"]),
  },
  methods: {
    loadList() {
      if (this.foyer != null) {
        this.listePatientHref = `${process.env.VUE_APP_API_URL}/liste_patient?pharmacie_id=${this.decoded.succursale.id}&foyer_id=${this.foyer}`;
      }
    },
  },
  mounted() {
    $axios.get(process.env.VUE_APP_API_URL + "/foyer?show_all=true").then((response) => {
      this.foyers = response.data;
    });
  },
};
</script>
