<template>
  <div>
    <piece-jointe></piece-jointe>
  </div>

</template>

<script>
import PieceJointe from '../components/PieceJointe.vue';

export default {
  name: 'Facturation',
  components: {
    PieceJointe
  },
}
</script>
